import React, { useEffect, useState } from 'react';
import * as Modal from './../../components/Atoms/Modal';

import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { CommonUtil } from '../../utils/commonUtil';
import Claim from '../../services/ClaimService';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import dayjs from 'dayjs';
import { ServiceProvider } from '../../services/index';

const partnerService = ServiceProvider.partner;
const WarrantyDiagnosis = ({
  detailData,
  carInfo,
  setWarrantyData,
  warrantyData,
  NEW,
  partnerList,
  setShowSmsType,
  setShowSmsModal,
  setSmsData,
  smsData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [carDetail, setCarDetail] = useState({});
  const [list, setList] = useState([]);
  const today = new Date();
  const [text, setText] = useState('');
  const [partnerText, setPartnerText] = useState('');
  const [selectedParnter, setSeletedPartner] = useState([]);
  const [partnerAllList, setPartnerAllList] = useState([]);

  const differenceInDays = (day1, day2) => {
    const date1 = new Date(day1);
    const date2 = new Date(day2);
    const differenceInMillis = Math.abs(date2 - date1);
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMillis / millisecondsPerDay
    );
    return differenceInDays;
  };

  const WorkDetailReport = () => {
    return (
      <>
        <Modal.Default
          header={`정비이력 상세`}
          onHide={() => setShowModal(!showModal)}
        >
          {carInfo?.workDetails.length > 0
            ? carInfo?.workDetails?.map((work, idx) => (
                <div key={idx} className="flex w-full border-1 mb-2">
                  <div className="border-right-1 p-3  surface-100">
                    <div>
                      수리 요청일 :{' '}
                      {dayjs(work.workRequestDate).format('YYYY-MM-DD')}
                    </div>
                    <div>
                      수리 완료일 :{' '}
                      {dayjs(work.workCompleteDate).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <div className=" p-3">
                    {work.workItems.map((item, idx) => (
                      <div key={idx}>{item.fullText}</div>
                    ))}
                  </div>
                </div>
              ))
            : '이력이 없습니다.'}
        </Modal.Default>
      </>
    );
  };

  useEffect(() => {
    if (!!carInfo) {
      const data = JSON.parse(carInfo.performanceCheckData.vehicleInformation);
      setCarDetail(data);
      setWarrantyData((ps) => ({
        ...ps,
        producingCountry: data.madeAt,
        checkDistance: data.TRVL_DSTNC,
        carName: data.CNM,
      }));
    }
  }, [carInfo]);

  const getDiagnosisType = async () => {
    try {
      const data = await Claim.getDocsType('I');
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDiagnosisType();
  }, []);

  const [findText, setFindText] = useState([]);
  useEffect(() => {
    if (typeof warrantyData.diagnosisType === 'string') {
      const typeData = warrantyData.diagnosisType.split(',');
      setFindText(typeData);
    }
  }, [list, warrantyData]);

  const headerTemplate = (
    <div className="flex justify-content-between">
      <div className="flex align-items-center">3. 보증 / 거절 / 진단</div>
      <div className="flex gap-2">
        <Button
          label="성능지 조회"
          icon="pi pi-file"
          className=""
          onClick={() =>
            carInfo?.performanceCheckData?.statementNumber
              ? CommonUtil.Window.popup(
                  `/performance-check/${carInfo.performanceCheckData.statementNumber}-${carInfo.performanceCheckData.dataId}/viewer`,
                  `pcv#${carInfo.performanceCheckData.statementNumber}`,
                  window.innerWidth * 0.8,
                  window.innerHeight * 0.8
                )
              : null
          }
        />
        <Button
          label="정비이력 조회"
          icon="pi pi-search"
          className=""
          onClick={() => setShowModal(!showModal)}
        />
      </div>
    </div>
  );

  const possible = warrantyData?.distanceDriven - carDetail.TRVL_DSTNC < 2000;
  const dayPossible =
    warrantyData?.buyDate && !detailData.receiptDate
      ? differenceInDays(today, warrantyData.buyDate) < 31
      : warrantyData?.buyDate && detailData.receiptDate
      ? differenceInDays(
          dayjs(detailData.receiptDate, 'YYYYMMDD'),
          dayjs(warrantyData.buyDate, 'YYYYMMDD')
        ) < 31
      : '미입력';

  const handleDeletePartner = (id) => {
    if (id) {
      const list = selectedParnter.filter((el) => el.partnerId !== Number(id));
      setSeletedPartner(list);
    }
  };

  const handleSelectPartner = (partner) => {
    if (selectedParnter.length < 5) {
      setSeletedPartner([...selectedParnter, partner]);
      setPartnerText(partner);
    } else {
      window.cerp.toast.warn('진단점은 최대 5개까지 등록 가능합니다.');
    }
  };

  useEffect(() => {
    if (partnerText) {
      setText('');
      setPartnerText('');
    }
  }, [partnerText]);

  useEffect(() => {
    if (selectedParnter.length) {
      const idList = [];
      selectedParnter.map((el) => idList.push(el.partnerId));
      setWarrantyData({ ...warrantyData, partnerId: idList.join() });
    }
  }, [selectedParnter]);

  const getPartnerList = async () => {
    try {
      const data = await partnerService.list({
        aids: ['1'],
        page: 1,
        size: 99,
      });
      if (data) {
        setPartnerAllList(data.data.list);
        // console.log(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPartnerList();
  }, []);

  useEffect(() => {
    if (warrantyData.partnerId && !!partnerAllList.length) {
      const ids = warrantyData.partnerId.split(',');
      const data = [];
      ids.forEach((id) =>
        data.push(partnerAllList.filter((el) => Number(id) === el.partnerId))
      );
      setSeletedPartner(data.flat());
    }
  }, [warrantyData.partnerId, partnerAllList]);

  return (
    <React.Fragment>
      <Panel header={headerTemplate} className="pt-3">
        <div className="grid">
          <div className="col-6">
            <Card title="보증 가능 여부 확인" style={{ height: '382px' }}>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    차량 인도일자
                  </label>
                </div>
                <div className="col">
                  <Calendar
                    maxDate={today}
                    value={
                      detailData.buyDate
                        ? CommonUtil.Formatter.stringToDate(detailData.buyDate)
                        : warrantyData.buyDate
                    }
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        buyDate: e.target.value,
                      }))
                    }
                    dateFormat="yy-mm-dd"
                    showButtonBar
                    showIcon
                    mask="9999-99-99"
                    placeholder="인도일자 선택"
                  />
                  {/* {warrantyData?.buyDate && !detailData.receiptDate && ( */}
                  <span className="ml-2">{`(경과일수: ${
                    warrantyData?.buyDate && !detailData.receiptDate
                      ? differenceInDays(today, warrantyData.buyDate)
                      : warrantyData?.buyDate && detailData.receiptDate
                      ? differenceInDays(
                          dayjs(detailData.receiptDate, 'YYYYMMDD'),
                          dayjs(warrantyData.buyDate, 'YYYYMMDD')
                        )
                      : ''
                  } 일)`}</span>
                  {/* )} */}
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    성능시 주행거리
                  </label>
                </div>
                <div className="col">
                  <InputText
                    readOnly
                    value={carDetail.TRVL_DSTNC || ''}
                    className=" w-full"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    접수시 주행거리
                  </label>
                </div>
                <div className="col">
                  <InputText
                    defaultValue=""
                    value={warrantyData?.distanceDriven || ''}
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        distanceDriven: e.target.value,
                      }))
                    }
                    className=" w-full"
                    placeholder="주행거리 입력"
                  />
                </div>
              </div>
              <div className="field grid ">
                <div className="col-3 font-bold">
                  <div>가능여부</div>
                </div>
                <div className="col text-left">
                  <div className="">
                    {!possible || !dayPossible ? (
                      <Tag severity="danger" value="불가능"></Tag>
                    ) : (
                      <Tag severity="success" value="가능"></Tag>
                    )}
                  </div>
                  {!dayPossible && (
                    <div className="mt-1">
                      인도일자로부터 30일이 넘어 보증이 되지 않습니다.
                    </div>
                  )}
                  {!possible && (
                    <div className="mt-1">
                      주행거리가 2000킬로가 넘어 보증이 되지 않습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-content-center">
                <Button
                  label="거절사유 SMS 전송"
                  // icon="pi pi-user"
                  className="p-button-outlined mr-1"
                  onClick={() => {
                    setShowSmsModal(true);
                    setShowSmsType('REJECTION');
                    setSmsData({
                      ...smsData,
                      warrnaty: { day: dayPossible, distance: possible },
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div className="col-6">
            <Card title="진단 정보">
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    국산 / 수입
                  </label>
                </div>
                <div className="col">
                  <InputText
                    readOnly
                    value={carDetail.madeAt === 'F' ? '수입' : '국산'}
                    className=" w-full"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    차량명
                  </label>
                </div>
                <div className="col">
                  <InputText
                    readOnly
                    value={carDetail.CNM || ''}
                    className=" w-full"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    진단부위
                  </label>
                </div>
                <div className="col overflow-hidden">
                  <MultiSelect
                    placeholder="선택"
                    className="overflow-hidden w-full"
                    filter
                    options={list}
                    optionLabel="label"
                    value={text ? text : !!findText.length ? findText : ''}
                    onChange={(e) => {
                      setWarrantyData((ps) => ({
                        ...ps,
                        diagnosisType: e.target.value,
                      }));
                      setText(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    방문가능일
                  </label>
                </div>
                <div className="col">
                  <Calendar
                    value={
                      detailData?.reservationDate
                        ? CommonUtil.Formatter.stringToDate(
                            detailData.reservationDate
                          )
                        : warrantyData.reservationDate
                    }
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        reservationDate: e.target.value,
                      }))
                    }
                    dateFormat="yy-mm-dd"
                    showButtonBar
                    showIcon
                    mask="9999-99-99"
                    placeholder="고객 방문 가능일 선택"
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    진단점 선택
                  </label>
                </div>
                <div className="col">
                  <div className="flex gap-2">
                    <Dropdown
                      className="mb-2"
                      placeholder="반경(km)"
                      options={['5', '10', '15', '20', '25', '30']}
                      onChange={(e) =>
                        setWarrantyData((ps) => ({
                          ...ps,
                          radius: e.target.value,
                        }))
                      }
                      value={warrantyData.radius || ''}
                    />
                    <Dropdown
                      value={partnerText}
                      onChange={(e) => handleSelectPartner(e.value)}
                      options={partnerList}
                      optionLabel="partnerName"
                      filter
                      placeholder="반경 선택 후 진단점을 선택해주세요."
                      className="w-full mb-2"
                      emptyMessage="조건에 맞는 진단점이 없습니다."
                    />
                  </div>
                </div>
              </div>
              <div className="field grid align-items-center">
                {!!selectedParnter.length && (
                  <div className="col-3">
                    <label className="font-bold" htmlFor="">
                      진단점명 / 연락처
                    </label>
                  </div>
                )}
                <div className="col">
                  {!!selectedParnter.length &&
                    selectedParnter.map((el, idx) => (
                      <div
                        key={idx}
                        className="flex gap-2 align-items-center mb-2"
                      >
                        <InputText value={el.partnerName} defaultValue="" />
                        <InputText
                          value={el.cellphone}
                          placeholder="진단점 연락처"
                          defaultValue=""
                        />
                        <Button
                          id={el.partnerId}
                          onClick={(e) => handleDeletePartner(e.target.id)}
                          icon="pi pi-minus-circle"
                          className="p-button-rounded p-button-outlined p-button-text p-button-secondary"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    진단점 메모
                  </label>
                </div>
                <div className="col">
                  <InputText
                    value={warrantyData.partnerNote || ''}
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        partnerNote: e.target.value,
                      }))
                    }
                    className="w-full"
                    placeholder="진단점 메모 입력"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="flex justify-content-center pt-2 gap-3">
                <Button
                  label="고객 진단점 안내 SMS 전송"
                  icon="pi pi-user"
                  className="p-button-outlined mr-1"
                  onClick={() => {
                    setShowSmsModal(true);
                    setShowSmsType('INFO_TO_CUSTOMER');
                    setSmsData({
                      ...smsData,
                      partnerInfo: selectedParnter,
                    });
                  }}
                />
                <Button
                  label="진단점 고객방문 SMS 전송"
                  icon="pi pi-car"
                  className="p-button-outlined mr-1"
                  onClick={() => {
                    setShowSmsModal(true);
                    setShowSmsType('INFO_TO_PARTNER');
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
      </Panel>
      {showModal && <WorkDetailReport />}
    </React.Fragment>
  );
};

export default WarrantyDiagnosis;
