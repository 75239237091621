import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import './configs/axiosConfig';

import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { addLocale, locale } from 'primereact/api';
import { touchUIState, userState } from './recoil/atoms';

import { AxiosInterceptor } from './configs/axiosConfig';
import BaseLayout from './pages/BaseLayout';
import { DialogUtil } from './utils/dialogUtil';
import GlobalDialog from './components/Common/GlobalDialog';
import Login from './pages/Login';
import MultiEditor from './components/Editor/MultiEditor';
import PCRegistrationForm from './pages/PerformanceCheck/PCRegistrationForm';
import PerformanceCheckViewer from './pages/PerformanceCheck/PerformanceCheckViewer';
import PerformanceCheckViewerMobile from './pages/PerformanceCheck/PerformanceCheckViewerMobile';
import ReportLoader from './pages/Report/ReportLoader';
import { Toast } from 'primereact/toast';
import { ToastUtil } from './utils/toastUtil';
import { YN } from './constants/Constants';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import ImagesView from './components/Accident/ImagesView';
import ClaimDialog from './pages/Accident/ClaimDialog';
import PartnerUpload from './pages/Accident/PartnerUpload';
import DealerUpload from './pages/Accident/DealerUpload';
import CounselingView from './pages/Accident/CounselingView';

addLocale('ko', {
  firstDayOfWeek: 0,
  dayNames: [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ],
  dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
  dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
  monthNames: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  monthNamesShort: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  today: '오늘',
  clear: '재설정',
});

locale('ko');

const isDesktop = () => window.innerWidth >= 992;

const App = ({ match, location, history }) => {
  const [touchUI, setTouchUI] = useRecoilState(touchUIState);
  const [user, setUser] = useRecoilState(userState);
  const [menu, setMenu] = useState([]);

  const toast = useRef(null);
  const dialog = useRef(null);
  const popups = useRef([]);

  if (!_.has(window, 'cerp')) window.cerp = {};
  window.cerp.toast = new ToastUtil(toast);
  window.cerp.dialog = new DialogUtil(dialog);
  window.cerp.popups = popups;

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === YN.YES;
    const accessToken = localStorage.getItem('accessToken');
    const UNAUTHENTICATED_PAGE_PATH = [
      '/mobile',
      '/office/rest/extservice',
      '/ext',
      '/upload/claim/',
      // '/upload/claim/dealer/',
      '/compensation/accident/claim/viewer',
    ];

    const { pathname, search } = location;

    if (isLoggedIn && accessToken) {
      const path = pathname || '/';

      setUser((prevState) => ({ ...prevState, isLoggedIn, accessToken }));

      if (path === '/login') {
        return history.replace('/');
      }

      for (let path of UNAUTHENTICATED_PAGE_PATH) {
        if (pathname.includes(path)) {
          return history.replace(`${pathname}${search}`);
        }
      }

      return history.replace(`${path}`);
    } else {
      for (let path of UNAUTHENTICATED_PAGE_PATH) {
        if (pathname.includes(path)) {
          return history.replace(`${pathname}${search}`);
        }
      }
      setUser({ isLoggedIn: false, accessToken: null });
      return history.replace('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onResize = () => setTouchUI(!isDesktop());
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = function no_console() {};
      console.warn = function no_console() {};
      console.error = function () {};
    }
  });

  return (
    <>
      <Toast ref={toast} />
      <GlobalDialog ref={dialog} />
      <AxiosInterceptor>
        <Switch>
          <Route exact path="/report/wrapper" component={ReportLoader} />
          <Route
            exact
            path="/performance-check/:statementNumber-:dataId/viewer"
            component={PerformanceCheckViewer}
          />
          <Route
            exact
            path="/performance-check/form"
            component={PCRegistrationForm}
          />
          <Route
            exact
            path="/performance-check/form/:statementNumber?-:dataId?"
            component={PCRegistrationForm}
          />
          <Route
            exact
            path="/performance-check/form/ext/:statementNumber?-:dataId?"
            component={PCRegistrationForm}
          />
          <Route
            exact
            path="/performance-check/:statementNumber/viewer/mobile"
            component={PerformanceCheckViewerMobile}
          />
          <Route
            path="/office/rest/extservice/OUT4511"
            component={PerformanceCheckViewerMobile}
          />
          <Route
            exact
            path="/compensation/accident/claim/viewer"
            component={ImagesView}
          />
          <Route
            exact
            path="/compensation/accident/claim/detail/counseling"
            component={CounselingView}
          />
          <Route
            exact
            path="/compensation/accident/claim/detail"
            component={ClaimDialog}
          />
          <Route
            exact
            path="/upload/claim/:carNum?&:cusN?&:cusC?&:dealer?"
            component={PartnerUpload}
          />

          <Route exact path="/edit" component={MultiEditor} />
          <Route exact path="/login" component={Login} />
          <Route path="/" component={BaseLayout} />
        </Switch>
      </AxiosInterceptor>
    </>
  );
};

export default withRouter(App);
