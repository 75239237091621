import * as Button from './../../../components/Atoms/Button';
import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CodeUtil } from '../../../utils/CodeUtil';
import { CommonUtil } from '../../../utils/commonUtil';
import FormUtil from '../../../utils/formUtil';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import Postcode from '../../Common/Postcode';
import { ServiceProvider } from '../../../services';
import UserRoleType from '../../../enums/UserRoleType';
import { YN } from '../../../constants/Constants';
import _ from 'lodash';
import { myInfoSelector } from '../../../recoil/selectors';
import { useFormValid } from '../../../hooks/useFormValid';
import { useRecoilValueLoadable } from 'recoil';
import { useHistory } from 'react-router-dom';

const partnerService = ServiceProvider.partner;
const kakaoService = ServiceProvider.kakao;

const formField = [
  {
    code: 'partnerName',
    title: '진단점명',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'partnerBasicInfo',
  },
  {
    code: 'businessRegistrationNumber',
    title: '사업자등록번호',
    type: 'biz',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'partnerBasicInfo',
  },
  {
    code: 'businessType',
    title: '업태',
    type: 'biz',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'partnerBasicInfo',
  },
  {
    code: 'businessItem',
    title: '업종',
    type: 'biz',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'partnerBasicInfo',
  },
  {
    code: 'mainPhone',
    title: '대표번호',
    type: 'phone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'cellphone',
    title: '휴대전화',
    type: 'cellphone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'representative',
    title: '대표자명',
    type: 'representative',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'email',
    title: '이메일',
    type: 'email',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'master',
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'postcode',
    title: '우편번호',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'postcode',
  },
  {
    code: 'address',
    title: '주소',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'address',
  },
  {
    code: 'addressDetail',
    title: '상세주소',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'addressDetail',
  },
  {
    code: 'bankName',
    title: '은행명',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'bankName',
  },
  {
    code: 'bankAccountNumber',
    title: '계좌번호',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'bankAccountNumber',
  },
  {
    code: 'reliability',
    title: '신뢰도',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'reliability',
  },
  {
    code: 'note',
    title: '메모',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'note',
  },
  {
    code: 'partnerLocation',
    type: 'text',
    defaultValues: {
      lat: '',
      lon: '',
    },
    required: true,
    pattern: false,
  },
];

export const PartnerDialog = ({
  userInfo,
  shopId = 0,
  onHide = null,
  partnerId,
  deletePartner,
}) => {
  const history = useHistory();
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};
    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm({
    defaultValues: getDefaultValues(formField),
    reValidateMode: 'onSubmit',
  });

  const isModified = partnerId > 0;

  const currentValues = useWatch({ control });

  const { isFormComplete } = useFormValid(watch(), formField, isModified);

  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    partnerCode: false,
    loginId: false,
  });

  const [partnerInfo, setPartnerInfo] = useState(null);
  useEffect(() => {
    if (partnerId) {
      getPartnerInfo(partnerId);
    }
  }, [partnerId]);

  const getPartnerInfo = async (id) => {
    try {
      const { data } = await partnerService.getData(id);

      if (data) {
        setPartnerInfo(data.partnerInfo);
        formField.forEach((el) => setValue(el.code, data.partnerInfo[el.code]));
        setIsAvailable({
          partnerCode: data.partnerInfo.partnerCode,
          loginId: data.partnerInfo.loginId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);

  async function getAddressInfo(address) {
    const {
      documents: [res],
    } = await kakaoService.getAddressInfo({
      query: address,
    });
    // const { x: lon, y: lat } = res;
    // console.log('좌표 정보', '경도', lon, '위도', lat);
    setValue('partnerLocation', `${res.y},${res.x}`);
  }

  function checkUserAuth(userInfo, type) {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  const getFilteredInputData = (formField, category) => {
    return formField.filter((item) => item.category === category);
  };

  const registerPartner = async () => {
    if (currentValues.password !== currentValues.passwordConfirmation) {
      return window.cerp.dialog.error('비밀번호가 일치하지 않습니다.');
    }
    const postData = {
      ...currentValues,
      associationId: myInfoLoadable.contents.associationInfo.associationId,
      useYn: 'Y',
      partnerCode: null,
    };
    // console.log('postData', postData);
    try {
      const data = await partnerService.register(postData);
      if (data) {
        window.cerp.toast.success('등록이 완료되었습니다.');
        onHide();
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(error?.message);
      onHide();
    }
  };

  const updatePartner = async () => {
    const updateData = {
      ...currentValues,
      associationId: myInfoLoadable.contents.associationInfo.associationId,
      useYn: 'Y',
      partnerCode: partnerInfo.partnerCode,
      partnerId: partnerInfo.partnerId,
      password: null,
      passwordConfirmation: null,
    };
    // console.log(updateData);
    try {
      const data = await partnerService.update(updateData);
      if (data) {
        window.cerp.toast.success('수정이 완료되었습니다.');
        onHide();
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(error?.message);
      onHide();
    }
  };

  return (
    <Modal.Form
      title={'진단점'}
      childDataName={'사용자'}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={() => deletePartner(partnerId)}
      onSubmitConfirm={isModified ? updatePartner : registerPartner}
      saveBtnEnable={!(isAvailable.loginId && isFormComplete)}
      deleteBtnVisible={
        checkUserAuth(userInfo, 'DELETE') || userInfo.roleCode === 'I_ADM'
      }
    >
      <form autoComplete="off">
        <Panel
          headerTemplate={
            <div className="p-panel-header">
              {isModified && checkUserAuth(userInfo, 'DISABLED') ? (
                <Controlled.InputSwitch
                  control={control}
                  watch={watch}
                  inputData={{
                    inputLabel: formField.filter(
                      (item) => item.code === 'useYn'
                    )[0].title,
                    toggleLabel: {
                      trueLabel: '사용',
                      falseLabel: '미사용',
                    },
                    dataLabel: 'useYn',
                  }}
                  styleClass={'font-semibold'}
                />
              ) : (
                <span className="font-semibold">1. 진단점 기본 정보</span>
              )}
            </div>
          }
        >
          <div className="grid">
            {_.filter(formField, { category: 'partnerBasicInfo' }).map(
              (item) => (
                <div
                  className="col-12 sm:col-6 lg:col-3"
                  key={`inputs_${item.code}`}
                >
                  <Controller
                    control={control}
                    name={item.code}
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                    }}
                    render={({ field }) => (
                      <Titled.InputText id={field.name} {...item} {...field} />
                    )}
                  />
                </div>
              )
            )}
          </div>
        </Panel>
        <Panel
          className="pt-3"
          headerTemplate={
            <div className="p-panel-header">
              <span className="font-semibold">2. 진단점 상세 정보</span>
            </div>
          }
        >
          <div className="grid">
            <div className="col-12 sm:col-6">
              <div className="field mb-0">
                <i className="text-red-400 pi pi-check mr-1" />
                <label htmlFor="address">주소</label>
              </div>
              <div
                className="p-inputgroup"
                onClick={() => setIsPostcodeOpen(true)}
              >
                <Controller
                  control={control}
                  name="postcode"
                  // defaultValue=""
                  rules={{
                    required: '필수 입력항목입니다.',
                  }}
                  render={({ field }) => (
                    <div className="p-inputgroup-addon">
                      {field.value || '우편번호'}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="address"
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      readOnly
                      className="bg-gray-100"
                    />
                  )}
                />
                <Button.Default
                  type="button"
                  label="주소검색"
                  icon="pi pi-search"
                  onClick={() => setIsPostcodeOpen(true)}
                />
              </div>
            </div>
            <div className="col-12 sm:col-6">
              <Controller
                control={control}
                name="addressDetail"
                defaultValue=""
                render={({ field }) => (
                  <Titled.InputText
                    id={field.name}
                    {...field}
                    title="상세주소"
                    disabled={_.isEmpty(currentValues['address'])}
                  />
                )}
              />
            </div>
            {/* <div className="col-12 sm:col-6 lg:col-3">
              <Controller
                control={control}
                name="majorLocation"
                defaultValue=""
                render={({ field }) => (
                  <Titled.Dropdown
                    id={field.name}
                    {...field}
                    title="지역 대분류"
                    required={false}
                    readOnly
                    placeholder="주소를 검색하세요."
                    option={codes.majorLocations}
                  />
                )}
              />
            </div>
            <div className="col-12 sm:col-6 lg:col-3">
              <Controller
                control={control}
                name="minorLocation"
                defaultValue=""
                render={({ field }) => (
                  <Titled.Dropdown
                    id={field.name}
                    {...field}
                    title="지역 소분류"
                    required={false}
                    readOnly
                    placeholder="주소를 검색하세요."
                    option={codes.minorLocations}
                  />
                )}
              />
            </div> */}

            <div className="col-12 sm:col-6 lg:col-3">
              <Controller
                control={control}
                name="bankName"
                defaultValue=""
                render={({ field }) => (
                  <Titled.InputText
                    id={field.name}
                    {...field}
                    title="은행명"
                    required={true}
                    placeholder="은행명"
                  />
                )}
              />
            </div>
            <div className="col-12 sm:col-6 lg:col-3">
              <Controller
                control={control}
                name="bankAccountNumber"
                render={({ field }) => (
                  <Titled.InputText
                    id={field.name}
                    {...field}
                    title="계좌번호"
                    required={true}
                    keyFilter="int"
                  />
                )}
              />
            </div>
          </div>
        </Panel>
        <Panel header="3. 마스터 계정 정보" className="pt-3">
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-3">
              <Controlled.ValidateInputText
                id={partnerId}
                isModified={isModified}
                control={control}
                trigger={trigger}
                currentValues={currentValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                getValues={getValues}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'loginId'
                  )[0].title,
                  dataLabel: 'loginId',
                }}
                inputConfig={{
                  placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                  autoComplete: 'new-loginId',
                }}
                rules={{
                  required: '필수 입력 항목입니다.',
                  // pattern: {
                  //   value: CommonUtil.Pattern['login'],
                  //   message: '유효하지 않은 포맷입니다.',
                  // },
                }}
              />
            </div>

            {!isModified &&
              getFilteredInputData(formField, 'password').map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controlled.InputPassword
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    inputConfig={{
                      autoComplete: 'chrome-off',
                    }}
                  />
                </div>
              ))}
          </div>
        </Panel>

        <Panel header="4. 기타" className="pt-3">
          <div className="grid">
            <div className="col-12">
              <Controller
                control={control}
                name="reliability"
                defaultValue="1"
                render={({ field }) => (
                  <Titled.RadioButton
                    id={field.name}
                    {...field}
                    title="신뢰도 구분"
                    // items={RELIABILITY}
                    items={[
                      { label: '상', value: '1' },
                      { label: '중', value: '2' },
                      { label: '하', value: '3' },
                    ]}
                  />
                )}
              />
            </div>
            <div className="col-12">
              <Controller
                control={control}
                name="note"
                defaultValue=""
                render={({ field }) => (
                  <div className="flex flex-column">
                    <label htmlFor="note">메모</label>
                    <InputTextarea
                      id={field.name}
                      {...field}
                      autoResize
                      rows={5}
                      cols={30}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>
      </form>

      <Postcode
        open={isPostcodeOpen}
        onHide={() => setIsPostcodeOpen(false)}
        onComplete={async (data) => {
          setIsPostcodeOpen(false);
          const res = await getAddressInfo(data.address);
          console.log(res);
          setValue('postcode', data.zonecode);
          setValue('address', data.address);
        }}
      />
    </Modal.Form>
  );
};
