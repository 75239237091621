import * as Button from './../Button';
import * as ConfirmDialog from './../../Dialog/ConfirmDialog';

import React, { useState } from 'react';

import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import FormUtil from '../../../utils/formUtil';

export const FormModal = ({
  title,
  childDataName,
  loading,
  isModified,
  onHide,
  onDeleteConfirm,
  onSubmitConfirm,
  saveBtnEnable,
  deleteBtnVisible,
  children,
  service,
  type,
  id,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState({
    delete: false,
    submit: false,
  });
  const DELETE = 'delete';
  const SUBMIT = 'submit';

  const handlePopupOpen = (type, action) => {
    setIsOpenPopup((ps) => ({ ...ps, [type]: action }));
  };

  return (
    <Dialog
      modal
      breakpoints={{ '960px': '90vw' }}
      header={isModified ? `${title} 정보 수정` : `${title} 신규 등록`}
      visible
      blockScroll
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button.Close onClose={onHide} />
          <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
            {isModified && (
              <ConfirmDialog.Delete
                visible={isOpenPopup[DELETE]}
                title={title}
                childDataName={childDataName}
                onDelete={onDeleteConfirm}
                onClose={() => handlePopupOpen(DELETE, false)}
              />
            )}
            <ConfirmDialog.Register
              visible={isOpenPopup[SUBMIT]}
              title={title}
              isModified={isModified}
              onAccept={onSubmitConfirm}
              onClose={() => handlePopupOpen(SUBMIT, false)}
            />
            {isModified && deleteBtnVisible && (
              <Button.Delete onDelete={() => handlePopupOpen(DELETE, true)} />
            )}
            <Button.Save
              disabled={saveBtnEnable}
              onSave={() => handlePopupOpen(SUBMIT, true)}
            />
          </div>
        </div>
      )}
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        {children}
      </BlockUI>
    </Dialog>
  );
};
