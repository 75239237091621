import React from 'react';
export const AppFooter = (props) => {
  return (
    <div className="layout-footer flex flex-column">
      <p>
        개인정보처리방침{' '}
        <a href="https://erp.carmon.co.kr/ikaat+_terms.html" target="_blank">
          이용약관
        </a>
      </p>
      <p>Copyrightⓒ 1990 - 2016 Korea Auto Check Center. All Right Reserved.</p>
    </div>
  );
};
