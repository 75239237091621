import React from 'react';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';

const Dashboard = () => {
  return (
    <Card title="사고 관리" className="dashboard-h">
      <div className="grid text-center">
        <div className="col-4">
          <Panel header="누적성능점검">{' - 건'}</Panel>
        </div>
        <div className="col-4">
          <Panel header="누적보증건수">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="처리율">- %</Panel>
        </div>

        <div className="col-4">
          <Panel header="접수중">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="접수완료">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="보증거절">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="진단안내">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="수리진행">- 건</Panel>
        </div>
        <div className="col-4">
          <Panel header="비용지급">- 건</Panel>
        </div>
      </div>
    </Card>
  );
};

export default Dashboard;
