import * as Button from './../../../components/Atoms/Button';
import * as ConfirmDialog from './../../../components/Dialog/ConfirmDialog';

import { COUNT_PER_PAGE, MAX_DATE, YN } from '../../../constants/Constants';
import React, { useEffect, useState } from 'react';
import {
  columnDateTime,
  columnUseYn,
  exportExcel,
} from '../../../utils/dataTableUtil';

import BaseCheckPriceDialog from './BaseCheckPriceDialog';
import { CodeUtil } from '../../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../../components/Menu/SearchMenu';
import ServiceMenu from '../../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../../services';
import UserPosition from '../../../enums/UserPosition';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import classNames from 'classnames';
import { myInfoSelector } from '../../../recoil/selectors';
import { useAuthorizedFetch } from '../../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  useYN: 'Y',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {
    name: { value: '', matchMode: 'contains' },
    'country.name': { value: '', matchMode: 'contains' },
    company: { value: '', matchMode: 'contains' },
    'representative.name': { value: '', matchMode: 'contains' },
  },
};

const baseCheckPriceService = ServiceProvider.baseCheckPrice;

const BaseCheckPriceList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    baseCheckPriceService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [codes, setCodes] = useState({});
  const [currentBaseCheckPriceTable, setCurrentBaseCheckPriceTable] =
    useState(null);

  const [isOpenDeletePopups, setIsOpenDeletePopups] = useState(false);

  const onDeleteConfirm = async (priceTableId) => {
    try {
      const { data } = await baseCheckPriceService.delete(priceTableId);

      window.cerp.toast.success(
        `점검료 테이블 삭제 완료`,
        `점검료 테이블을 삭제했습니다.`
      );
      // console.log(data, searchConditions);
      await getList(searchConditions);
    } catch (error) {
      window.cerp.dialog.error(
        '점검료 테이블 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  };

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // not A_OFF, S_INS, S_OFF, C~
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'A_OFF' ||
        r.value === 'S_INS' ||
        r.value === 'S_OFF' ||
        _.startsWith(r.value, 'C_')
      );
    });

    return !(_.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0);
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        const [associations, associationId, association] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: _.get(myInfo, 'myAssociationId'),
          });
        const [enterprises, enterpriseId, enterprise] =
          await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: _.get(myInfo, 'myAssociationId'),
          });

        setCodes((ps) => ({ ...ps, associations, enterprises }));

        await getList({
          ...searchConditions,
          associations: [associationId],
          enterprises: [enterpriseId],
        });
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.contents]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() =>
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              associationId: value,
                            });
                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                            },
                            {
                              enterprises: [],
                            }
                          );
                        }}
                      />
                    </div>
                    {/* <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">점검법인구분</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_enterpriseType_all"
                            name="enterpriseType"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                enterpriseType: value,
                              }));
                            }}
                            checked={searchConditions.enterpriseType === ''}
                          />
                          <label htmlFor="radio_enterpriseType_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_enterpriseType_direct"
                            name="enterpriseType"
                            value="AD"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                enterpriseType: value,
                              }));
                            }}
                            checked={searchConditions.enterpriseType === 'AD'}
                          />
                          <label htmlFor="radio_enterpriseType_direct">
                            협회직영
                          </label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_enterpriseType_franchise"
                            name="enterpriseType"
                            value="FR"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                enterpriseType: value,
                              }));
                            }}
                            checked={searchConditions.enterpriseType === 'FR'}
                          />
                          <label htmlFor="radio_enterpriseType_franchise">
                            프랜차이즈(법인)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">점검업체</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder={
                          searchConditions.searchType === 'C'
                            ? '점검업체 코드, 점검업체명으로 검색'
                            : '담당자 이름, 연락처로 검색'
                        }
                        onChange={({ target: { value } }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: value,
                          }));
                        }}
                      />
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_searchType_enterprise"
                            name="searchType"
                            value="C"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                searchType: value,
                              }));
                            }}
                            checked={searchConditions.searchType === 'C'}
                          />
                          <label htmlFor="radio_searchType_enterprise">
                            점검업체
                          </label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_searchType_person"
                            name="searchType"
                            value="P"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                searchType: value,
                              }));
                            }}
                            checked={searchConditions.searchType === 'P'}
                          />
                          <label htmlFor="radio_searchType_person">
                            담당자
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속업체</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showClear
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          handleSelectAll(value, 'enterprises');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>

                  {/* <ServiceMenu
                    title={'점검료 테이블'}
                    onClickRegisterBtn={() => {
                      setCurrentBaseCheckPriceTable({
                        enterpriseId: 0,
                        priceTableId: 0,
                      })
                    }}
                    excelData={{
                      type: '',
                      params: {
                        ...searchConditions,
                        page: lazyParams.page + 1,
                        size: lazyParams.rows
                      }
                    }}
                  /> */}

                  {checkRegistAuth(myInfoLoadable.contents) && (
                    <Panel className="shadow-1 mb-3">
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                        <Button.Default
                          label="점검료 신규 등록"
                          onClick={() =>
                            setCurrentBaseCheckPriceTable({
                              enterpriseId: 0,
                              priceTableId: 0,
                            })
                          }
                        />

                        {/* 추가 개발 */}
                        {/* <Button.Default
                        label="엑셀 다운로드"
                        className="p-button-outlined"
                        icon="pi pi-download"
                        onClick={() => exportExcel(data, '점검법인리스트')}
                      /> */}
                      </div>
                    </Panel>
                  )}

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      loading={loading}
                      rowClassName={({ isNow }) =>
                        isNow === YN.YES ? 'bg-blue-50 font-bold' : ''
                      }
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="title"
                        header="점검료"
                        body={({
                          priceTableId,
                          associationId,
                          enterpriseId,
                          title,
                          isNow,
                          isDeletable,
                        }) => (
                          <a
                            href="."
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentBaseCheckPriceTable({
                                associationId,
                                enterpriseId,
                                priceTableId,
                                isDeletable,
                              });
                            }}
                          >
                            <i
                              className={classNames('mr-1', {
                                'pi pi-check': isNow === YN.YES,
                              })}
                            />
                            {title || '기본점검료테이블'}
                          </a>
                        )}
                      />
                      {checkRegistAuth(myInfoLoadable.contents) && (
                        <Column
                          field="startDate"
                          header="삭제"
                          className="text-center"
                          body={({
                            priceTableId,
                            enterpriseId,
                            title,
                            isDeletable,
                          }) =>
                            isDeletable === YN.YES && (
                              <>
                                <ConfirmDialog.Delete
                                  visible={
                                    isOpenDeletePopups[
                                      `${enterpriseId}_${priceTableId}`
                                    ]
                                  }
                                  title={`점검료 테이블 ${title}`}
                                  onDelete={() => onDeleteConfirm(priceTableId)}
                                  onClose={() =>
                                    setIsOpenDeletePopups((ps) => ({
                                      ...ps,
                                      [`${enterpriseId}_${priceTableId}`]: false,
                                    }))
                                  }
                                />
                                <Button.Delete
                                  onDelete={() =>
                                    setIsOpenDeletePopups((ps) => ({
                                      ...ps,
                                      [`${enterpriseId}_${priceTableId}`]: true,
                                    }))
                                  }
                                />
                              </>
                            )
                          }
                        />
                      )}
                      <Column
                        field="associationCode"
                        header={
                          <span>
                            협회
                            <br />
                            협회코드
                          </span>
                        }
                        body={({ associationCode, associationName }) => (
                          <span>
                            {associationName}
                            <br />[{associationCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseCode"
                        header={
                          <span>
                            점검업체
                            <br />
                            점검업체코드
                          </span>
                        }
                        body={({ enterpriseCode, enterpriseName }) => (
                          <span>
                            {enterpriseName}
                            <br />[{enterpriseCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="startDate"
                        header={
                          <div>
                            <span>적용시작일</span>
                            <br />
                            <span>적용만료일</span>
                          </div>
                        }
                        body={({ startDate, endDate }) => (
                          <div>
                            <span>{startDate}</span>
                            <br />
                            <span>
                              {' '}
                              ~ {endDate === MAX_DATE ? '계속' : endDate}
                            </span>
                          </div>
                        )}
                      />
                      <Column field="description" header="비고" />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentBaseCheckPriceTable !== null && (
                    <BaseCheckPriceDialog
                      checkPriceTable={currentBaseCheckPriceTable}
                      userInfo={myInfoLoadable.contents}
                      onHide={async () => {
                        setCurrentBaseCheckPriceTable(null);
                        await getList(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                      }}
                    />
                  )}

                  {/*<BaseCheckPriceDialog*/}
                  {/*  checkPriceTable={{*/}
                  {/*    associationId: 1,*/}
                  {/*    enterpriseId: 2,*/}
                  {/*    priceTableId: 1,*/}
                  {/*    isDeletable: false,*/}
                  {/*  }}*/}
                  {/*  onHide={async () => {*/}
                  {/*    setCurrentBaseCheckPriceTable(null);*/}
                  {/*    await getList();*/}
                  {/*  }}*/}
                  {/*/>*/}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;
            case 'hasError':
              return null;
            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BaseCheckPriceList, comparisonFn);
