import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import Claim from '../../services/ClaimService';
import * as Button from './../../components/Atoms/Button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import CustomerDocument from '../../components/Accident/CustomerDocument';

const PartnerUpload = () => {
  let { carNum, cusN, cusC, dealer } = useParams();

  const carNbr = carNum.split('=')[1];
  const customerName = cusN.split('=')[1];
  const customerContact = cusC.split('=')[1];
  const dealerUser = dealer.split('=')[1] === 'true' ? true : false;

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('images');
  }, []);

  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [docs, setDocs] = useState([]); // claimDocs
  const [copyFile, setCopyFile] = useState([]); // 사진 파일 카피
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [carDetailData, setCarDetailData] = useState(null); // 자동자 상세정보
  const [statusCode, setStatusCode] = useState('');
  const [warrantyData, setWarrantyData] = useState({
    buyDate: '',
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: '',
    carName: '',
    partnerId: null, //opt
    partnerName: null, //Opt
    partnerContact: null, //Opt
    partnerLocation: null,
  });
  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
  });
  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
  }); // 수리정보
  const [loading, setLoading] = useState(false);

  const getDetailData = async () => {
    setLoading(true);
    try {
      const data = await Claim.getClaimDetail({
        carNbr,
        customerName,
        customerContact,
      });
      if (data) {
        // console.log(data);
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setWarrantyData({
          buyDate: data.claimData.buyDate,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerId: data.claimData.partnerId,
          partnerName: data.claimData.partnerName, //Opt
          partnerContact: data.claimData.partnerContact, //Opt
          partnerLocation: data.claimData.partnerLocation, //Opt
        });
        setStatusCode(data.claimData.claimStatus);

        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkWarranty = async (carNbr) => {
    setLoading(true);
    try {
      const data = await Claim.getCarDetail(carNbr);
      const vehicleInformation = JSON.parse(
        data.performanceCheckData.vehicleInformation
      );

      if (data) {
        setLoading(false);
        setCarDetailData({
          ...data,
          carVIN: vehicleInformation.VIN + vehicleInformation.vinRemain,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.cerp.toast.warn(error.response.data.error?.message);
    }
  };

  useEffect(() => {
    if (carNbr && customerName && customerContact) {
      getDetailData();
      checkWarranty(carNbr);
    }
  }, [carNbr, customerName, customerContact, dealerUser]);

  const paymentInfo = carDetailData
    ? JSON.parse(carDetailData?.performanceCheckData?.paymentInformation)
    : '';

  //TODO - 클레임 수정 PUT
  const changetClaim = async () => {
    setLoading(true);
    let fileDataList = [];
    let filesList = [];

    const del = [];
    if (!!deleteImgs.length) {
      // console.log('삭제하려는 이미지가 있는 경우 => 기존 이미지');
      deleteImgs.map((el) =>
        del.push({ imgId: el.imgId, documentCode: el.documentCode, state: 'D' })
      );

      // console.log(del);
      fileDataList = del;
    }
    const findFile = fileItems.find((el) => el.file); // File 이 있는지 찾는다.
    const findImgId = fileItems.find((el) => el.imgId); // 기존 이미지를 찾는다.
    const idFile = []; // imgId 를 가지고 있는 기존 이미지파일
    fileItems.filter((el) => el.imgId && idFile.push(el));
    const imgFile = []; // file 을 가지고 있는 새로 등록한 이미지 파일
    fileItems.filter((el) => el.file && imgFile.push(el));

    if (findFile && !findImgId) {
      // console.log('새 이미지');
      fileItems.map((el) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => filesList.push(el.file));
    } else if (!findFile && findImgId) {
      // console.log('파일이 없고, 아이디만 있다 => 기존 이미지');
    } else if (findFile && findImgId) {
      // console.log('파일이 있고, 아이디도 있다 => 새 이미지 + 기존 이미지');

      imgFile.map((el, idx) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      imgFile.map((el) => filesList.push(el.file));
    }

    const digType = [];
    if (
      typeof warrantyData.diagnosisType !== 'string' &&
      warrantyData.diagnosisType
    ) {
      warrantyData.diagnosisType.map((el) =>
        digType.push(el.partCode + el.partDetailCode)
      );
    }
    digType.toString();

    const maintenanceType = [];
    if (!!repairData.maintenanceCode.length) {
      repairData.maintenanceCode.map((el) =>
        maintenanceType.push(el.partCode + el.partDetailCode)
      );
    }
    maintenanceType.toString();

    const updateData = {
      funnelsType: detailData.funnelsType, // W or A
      claimStatus: statusCode,
      customerName: detailData.customerName,
      customerContact: detailData.customerContact,
      statementNumber: carDetailData.performanceCheckData.statementNumber,
      carNbr: carDetailData.performanceCheckData.VHRNO,
      checkDate: carDetailData.performanceCheckData.checkDate,
      buyDate:
        typeof warrantyData.buyDate !== 'string'
          ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
          : detailData?.buyDate,
      checkDistance: warrantyData.checkDistance, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven,
      producingCountry: warrantyData.producingCountry, // D or F
      carName: warrantyData.carName,
      carLocation: detailData.carLocation, //Opt
      diagnosisType: digType.join() ? digType.join() : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId,
      reservationDate:
        typeof warrantyData.reservationDate === 'string'
          ? warrantyData.reservationDate
          : warrantyData.reservationDate
          ? CommonUtil.Formatter.dateToString(warrantyData.reservationDate)
          : null, //Opt
      partnerId: warrantyData.partnerId ? warrantyData.partnerId : null,
      partnerName: warrantyData.partnerName ? warrantyData.partnerName : null, //Opt
      partnerContact: warrantyData.partnerContact
        ? warrantyData.partnerContact
        : null, //Opt
      partnerLocation: warrantyData.partnerLocation
        ? warrantyData.partnerLocation
        : null,
      receiptId: detailData.userId ? detailData.userId : null,
      receiptDate: detailData?.receiptDate, // 접수 일자
      documentGroupId: detailData?.documentGroupId, //Opt
      note: null, //Opt
      partenerReceiptDate:
        typeof repairData.date !== 'string'
          ? CommonUtil.Formatter.dateToString(repairData.date)
          : detailData?.partenerReceiptDate, //Opt 수리 입고일자
      maintenanceCode: detailData?.maintenanceCode
        ? detailData?.maintenanceCode
        : maintenanceType.length > 0
        ? maintenanceType.join()
        : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: paymentInfo.ICNY_CODE, // opt 보험코드
      insurancePolicyNumber: paymentInfo.insurancePolicyNumber, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.payDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.payDate)
        : null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt
        ? insuranceInfo.paymentAmt
        : null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt
        ? insuranceInfo.deductibleAmt
        : null, // opt 자기부담금
      partnerNote: null, //Opt
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: detailData.completeLoginId, //Opt 로그인유저 아이디
      completeLoginContact: detailData.completeLoginContact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };
    // console.log('data =>', updateData);
    // console.log('files =>', filesList);

    const checkData =
      !!updateData.checkDate &&
      // !!updateData.claimStatus &&
      !!updateData.checkDistance &&
      !!updateData.producingCountry &&
      !!updateData.carName &&
      // !!updateData.diagnosisType &&
      // !!updateData.receiptId &&
      !!updateData.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }

    try {
      const data = await Claim.partnerUpload(updateData, filesList);
      if (data) {
        setLoading(false);
        window.cerp.toast.info('업로드가 완료 되었습니다.');
        // history.push('/compensation/accident/list');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (docs) {
      setFileItems(docs);
      setCopyFile(docs);
    }
  }, [docs]);

  return (
    <div className="p-4">
      <div className="flex justify-content-center">
        <h2 className="font-bold">
          {dealerUser ? '매매상사 사진 업로드' : '진단점 차량 사진 업로드'}
        </h2>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <Panel header="1. 차량 정보">
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차량번호
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={detailData?.carNbr}
                name="carNum"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차대번호
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={carDetailData?.carVIN}
                name="carVIN"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차량명
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={detailData?.carName}
                name="carVIN"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
        </Panel>
        {dealerUser ? (
          <CustomerDocument
            deleteImgs={deleteImgs}
            setDeleteImgs={setDeleteImgs}
            fileItems={fileItems}
            setFileItems={setFileItems}
            copyFile={copyFile}
          />
        ) : (
          <PartnerDocument
            deleteImgs={deleteImgs}
            setDeleteImgs={setDeleteImgs}
            fileItems={fileItems}
            setFileItems={setFileItems}
            copyFile={copyFile}
          />
        )}
        <div className="flex justify-content-center my-5">
          <Button.Default
            className="text-2xl font-bold"
            icon="pi pi-save text-2xl"
            label="사진 업로드 하기"
            onClick={() => changetClaim()}
          />
        </div>
      </BlockUI>
    </div>
  );
};

export default PartnerUpload;
