import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { CommonUtil } from '../../utils/commonUtil';
const SendSMS = ({
  onHide,
  smsData,
  setSmsData,
  customerInfo,
  showSmsType,
  warrantyData,
  postSms,
}) => {
  // console.log(smsData.partnerInfo);

  const setPartnerInfo = (info) => {
    info.map((el) => {
      return <div>- 진단점명 : </div>;
    });
  };

  const makeContent = (type, customerInfo) => {
    switch (type) {
      case 'APP_DOWNLOAD':
        setSmsData({
          ...smsData,
          url: '',
          contents: `${customerInfo?.customerName} 고객님, 안녕하세요. 성능접수를 위해 전달 드리는 링크를 통해 APP 을 다운로드 받으신 후 차량정보 및 서류를 업로드해 주세요.\r\n\r\n 감사합니다.`,
        });

        break;
      case 'REJECTION':
        setSmsData({
          ...smsData,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${
            customerInfo?.customerName
          } 고객님, 안녕하세요. 자동차성능·상태점검책임보험의 보장기간이 경과하여 보증이 불가하여 안내 드립니다. 자세한 내용은 아래를 참고 부탁 드립니다.\r\n ${
            !smsData.warrnaty.day ? '- 인도일자로부터 30일 경과' : ''
          } \r\n ${
            !smsData.warrnaty.distance ? '- 주행거리 2,000Km 초과' : ''
          }\r\n\r\n*자동차성능·상태점검책임보험의 보장기간\r\n - 자동차 인도일로부터 30일 또는 주행거리 2천킬로미터 이내 중 먼저 도래한 기준 이내
          \r\n감사합니다.`,
        });
        break;
      case 'INFO_TO_CUSTOMER':
        setSmsData({
          ...smsData,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${customerInfo?.customerName} 고객님, 안녕하세요. 방문하실 진단점 정보 안내 드립니다.\r\n\r\n - 진단점명 : ${warrantyData.partnerName}\r\n - 연락처 : ${warrantyData.partnerContact}\r\n - 위치 : ${warrantyData.partnerLocation}\r\n\r\n감사합니다.`,
        });
        break;
      case 'INFO_TO_PARTNER':
        setSmsData({
          ...smsData,
          destAddr: `${warrantyData.partnerContact}`,
          contents: `안녕하세요. 방문예정 고객님의 정보를 전달 드립니다. 함께 전달 드리는 URL 을 통해 진단 사진을 업로드해주세요.\r\n\r\n - 고객명 : ${customerInfo?.customerName}\r\n - 연락처 : ${customerInfo.customerContact}\r\n - 차량번호 : ${customerInfo.carNbr}\r\n\r\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/carNum=${customerInfo.carNbr}&cusN=${customerInfo.customerName}&cusC=${customerInfo.customerContact}&dealer=false`,
        });
        break;
      case 'PARTNER_UPLOAD_URL':
        setSmsData({
          ...smsData,
          contents: `안녕하세요. 전달 드리는 URL 을 통해 수리 사진을 업로드해주세요.\r\n\r\n - 고객명 : ${customerInfo?.customerName}\r\n - 연락처 : ${customerInfo.customerContact}\r\n - 차량번호 : ${customerInfo.carNbr}\r\n\r\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/carNum=${customerInfo.carNbr}&cusN=${customerInfo.customerName}&cusC=${customerInfo.customerContact}&dealer=false`,
        });
        break;
      case 'DEALER_UPLOAD_URL':
        setSmsData({
          ...smsData,
          contents: `안녕하세요. 전달 드리는 URL 을 통해 서류를 업로드해주세요.\r\n\r\n - 고객명 : ${customerInfo?.customerName}\r\n - 연락처 : ${customerInfo.customerContact}\r\n - 차량번호 : ${customerInfo.carNbr}\r\n\r\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/carNum=${customerInfo.carNbr}&cusN=${customerInfo.customerName}&cusC=${customerInfo.customerContact}&dealer=true`,
        });
        break;
      default:
    }
  };

  useEffect(() => {
    if (showSmsType) {
      makeContent(showSmsType, customerInfo);
    }
  }, [showSmsType, customerInfo]);

  return (
    <Dialog
      onHide={onHide}
      modal
      visible
      style={{ width: '340px' }}
      header={'SMS 전송 - ' + SMSType[showSmsType].title}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-center pt-2">
          <Button
            label="전송하기"
            icon="pi pi-check-circle"
            className=" mr-1"
            onClick={() => postSms(SMSType[showSmsType].title)}
          />
        </div>
      )}
    >
      <div className="">고객명 : {customerInfo?.customerName}</div>
      <div className="my-2">
        연락처 : {CommonUtil.Formatter.phone(customerInfo?.customerContact)}
      </div>
      <Divider></Divider>
      <div className="flex align-items-center">
        <label>수신번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.destAddr)}
          onChange={(e) => setSmsData({ ...smsData, destAddr: e.target.value })}
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <div className="flex align-items-center mt-1">
        <label>발송번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.sourceAddr)}
          onChange={(e) =>
            setSmsData({ ...smsData, sourceAddr: e.target.value })
          }
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <Divider />
      <div>발송내역</div>
      <div className="mt-2">
        <InputTextarea
          className="w-full mt-1"
          rows={4}
          autoResize
          value={
            smsData?.contents || ''
            // makeContent(SMSType[showSmsType], customerInfo?.customerName)
          }
          onChange={({ target: { value } }) =>
            setSmsData({ ...smsData, contents: value })
          }
          // disabled={_.isEmpty(performanceCheckData)}
        />
      </div>
    </Dialog>
  );
};

export default SendSMS;

const SMSType = {
  APP_DOWNLOAD: {
    title: '성능접수 안내',
  },
  REJECTION: {
    title: '보증 거절 안내',
    content: '',
  },
  INFO_TO_CUSTOMER: {
    title: '진단점 정보 안내',
    content: '',
  },
  INFO_TO_PARTNER: {
    title: '진단점 고객정보 안내',
    content: '',
  },
  PARTNER_UPLOAD_URL: {
    title: '수리사진 업로드 안내',
    content: '',
  },
  DEALER_UPLOAD_URL: {
    title: '매매상사 서류 업로드 안내',
  },
};
