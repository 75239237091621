import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CodeUtil } from '../../../utils/CodeUtil';
import { CommonUtil } from '../../../utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import FormUtil from '../../../utils/formUtil';
import { Image } from 'primereact/image';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { ServiceProvider } from '../../../services';
import UserPosition from './../../../enums/UserPosition';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import dayjs from 'dayjs';
import { myInfoSelector } from '../../../recoil/selectors';
import { touchUIState } from '../../../recoil/atoms';
import { useDropzone } from 'react-dropzone';
import { useFormValid } from '../../../hooks/useFormValid';

const formField = [
  {
    code: 'userName',
    title: '이름',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'userBasicInfo',
  },
  {
    code: 'contact',
    title: '휴대전화',
    type: 'cellphone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'userBasicInfo',
  },
  {
    code: 'email',
    title: '이메일',
    type: 'email',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'userBasicInfo',
  },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'useYn',
    title: '1. 사용자 기본 정보',
    type: 'boolean',
    defaultValue: 'Y',
    required: true,
    pattern: false,
  },
  {
    code: 'associationId',
    title: '소속협회',
    dataKey: 'associations',
    placeholder: '협회없음',
    defaultValue: 0,
    required: () => true,
    category: 'dropdown',
  },
  {
    code: 'enterpriseId',
    title: '소속점검법인',
    dataKey: 'enterprises',
    placeholder: '점검법인없음',
    defaultValue: 0,
    // required: true,
    required: (roleCode) => {
      if (_.startsWith(roleCode, 'A_') || _.startsWith(roleCode, 'I_')) {
        return false;
      } else {
        return true;
      }
    },
    category: 'dropdown',
  },
  {
    code: 'shopId',
    title: '소속점검장',
    dataKey: 'shops',
    placeholder: '점검장없음',
    defaultValue: 0,
    // required: true,
    required: (roleCode) => {
      if (
        _.startsWith(roleCode, 'A_') ||
        _.startsWith(roleCode, 'I_') ||
        _.startsWith(roleCode, 'E_')
      ) {
        return false;
      } else {
        return true;
      }
    },
    category: 'dropdown',
  },
  {
    code: 'roleCode',
    title: '계정권한',
    dataKey: 'roleCodes',
    placeholder: '계정권한없음',
    defaultValue: 0,
    required: () => true,
    validate: (v) => v !== '' || '선택된 값이 없습니다.',
    category: 'dropdown',
  },
  {
    code: 'certificateNumber',
    title: '자격증번호',
    required: false,
    type: 'text',
  },
  {
    code: 'certificateType',
    title: '자격증구분',
    required: false,
    type: 'dropdown',
  },
  {
    code: 'joinDate',
    title: '소속일',
    defaultValue: dayjs().toDate(),
    required: false,
    type: 'date',
  },
];

const MIN_YEAR = 2010;
let today = dayjs();

const startDate = today.toDate();
const endDate = today.endOf('month').toDate();

const codeService = ServiceProvider.code;
const userService = ServiceProvider.user;

export const UserDialog = ({ userInfo, userId = 0, onHide = null }) => {
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const touchUI = useRecoilValue(touchUIState);

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};

    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const {
    control,
    formState,
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm({
    defaultValues: getDefaultValues(formField),
    reValidateMode: 'onSubmit',
  });
  const currentValues = useWatch({ control });

  /* 다이얼로그 진입시 id가 있으면 수정 || 없으면 등록 */
  const isModified = userId > 0;
  const { isFormComplete } = useFormValid(watch(), formField, isModified);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

  const [codes, setCodes] = useState({});
  const [userData, setUserData] = useState(null);
  const [officialSeals, setOfficialSeals] = useState(null);

  const [needReset, setNeedReset] = useState(false);
  const [isAvailable, setIsAvailable] = useState({ loginId: false });
  const [loading, setLoading] = useState(false);

  const [newPassword, setNewPassword] = useState('');

  const getFilteredInputData = (formField, category) => {
    return formField.filter((item) => item.category === category);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      setOfficialSeals(acceptedFiles);
    },
  });

  async function getRoleCodes({
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
    roleCode,
  }) {
    const { data: roleCodes } = await codeService.userRoles(
      associationId || 0,
      enterpriseId || 0,
      shopId || 0
    );

    const { value: userRole = '' } = roleCodes[0] || '';
    return [roleCodes, roleCode || userRole];
  }

  function getOfficialSealPath(officialSeals, uploadedOfficialSealPath) {
    // 유저가 업로드한 직인 이미지들이 있으면
    if (officialSeals !== null) {
      return officialSeals[0].preview;
    }

    // 조회한 협회 정보에 officialPath가 있으면
    if (uploadedOfficialSealPath) {
      if (_.startsWith(uploadedOfficialSealPath, '/')) {
        // /로 시작하는 url -> S3
        return process.env.REACT_APP_S3_BASE_URL + uploadedOfficialSealPath;
      } else {
        return uploadedOfficialSealPath;
      }
    }

    return '';
  }

  function fillEmptyField(data) {
    const copy = { ...data };
    for (let field in copy) {
      copy[field] = copy[field] ?? '';
    }

    return copy;
  }

  function checkResetPwAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  async function resetPw(changePW) {
    try {
      const { data } = await userService.resetPassword({
        loginId: _.get(currentValues, 'loginId'),
        changePW,
      });

      if (data) {
        setShowConfirmPopup(false);
        setNewPassword('');

        window.cerp.toast.success(
          '비밀번호 초기화 완료',
          `${_.get(currentValues, 'userName')}님의 비밀번호를 초기화 했습니다.`
        );
      }
    } catch (error) {
      window.cerp.dialog.error(
        '비밀번호 초기화 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  function checkPwValidation(changePw) {
    // 비밀번호 규격에 적합해야 함 -> validation check를 언제 하지?
    let isValid = false;
    let errorMsg = '';

    if (CommonUtil.Validator.password(changePw)) {
      isValid = true;
    } else {
      errorMsg += '비밀번호는 영문, 숫자를 포함한 4자리 이상이어야 합니다.';
    }

    return [isValid, errorMsg];
  }

  function checkUserAuth(userInfo, type) {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  async function handleCodeDropdown(
    userInfo,
    {
      selectedAssociationId,
      selectedEnterpriseId,
      selectedShopId,
      selectedRoleCode,
    }
  ) {
    const [associations, associationId, association] =
      await CodeUtil.getAssociationCodes(userInfo, {
        preSelectedAssociationId: selectedAssociationId,
      });
    const [enterprises, enterpriseId, enterprise] =
      await CodeUtil.getEnterpriseCodes(userInfo, {
        isParentEmpty: _.isEmpty(associations),
        associationId: selectedAssociationId || associationId,
      });
    const [shops, shopId, shop] = await CodeUtil.getShopCodes(userInfo, {
      isParentEmpty: _.isEmpty(enterprises),
      enterpriseId: selectedEnterpriseId || enterpriseId,
    });
    const [roleCodes, roleCode] = await getRoleCodes({
      associationId: selectedAssociationId || associationId,
      enterpriseId: selectedEnterpriseId || enterpriseId,
      shopId: selectedShopId || shopId,
    });

    setCodes((ps) => ({
      ...ps,
      associations,
      enterprises,
      shops,
      roleCodes,
    }));

    setValue('associationId', selectedAssociationId || associationId);
    setValue('enterpriseId', selectedEnterpriseId || enterpriseId);
    setValue('shopId', selectedShopId || shopId);

    if (selectedRoleCode === 'C_ADM') {
      //* alert 띄워주기
      window.cerp.dialog.info(
        '상사 계정 등록/수정 오류',
        '매매상사 계정 등록이나 정보 수정은 [고객관리]-[매매상사목록]에서만 가능합니다.'
      );
      setValue('roleCode', roleCode);
      return;
    }

    setValue('roleCode', selectedRoleCode || roleCode);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(userInfo);
        if (!isModified) {
          const [associations, associationId, association] =
            await CodeUtil.getAssociationCodes(myInfo, {
              preSelectedAssociationId: myAssociationId,
            });
          const [enterprises, enterpriseId, enterprise] =
            await CodeUtil.getEnterpriseCodes(myInfo, {
              isParentEmpty: _.isEmpty(associations),
              associationId: myAssociationId,
            });
          const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
            isParentEmpty: _.isEmpty(enterprises),
            enterpriseId: myEnterpriseId,
          });

          const [roleCodes, roleCode] = await getRoleCodes(
            associationId,
            enterpriseId,
            shopId
          );

          setCodes({ associations, enterprises, shops, roleCodes });
          setValue('associationId', associationId);
          setValue('enterpriseId', enterpriseId);
          setValue('shopId', shopId);
          setValue('roleCode', roleCode);
        } else {
          try {
            const { data } = await userService.getData(userId);
            const { associationId, enterpriseId, shopId, roleCode } = data;

            const [associations] = await CodeUtil.getAssociationCodes(myInfo, {
              preSelectedAssociationId: associationId,
            });
            const [enterprises] = await CodeUtil.getEnterpriseCodes(myInfo, {
              isParentEmpty: _.isEmpty(associations),
              associationId: associationId,
            });
            const [shops] = await CodeUtil.getShopCodes(myInfo, {
              isParentEmpty: _.isEmpty(enterprises),
              enterpriseId: enterpriseId,
            });
            const [roleCodes] = await getRoleCodes(
              associationId,
              enterpriseId,
              shopId,
              roleCode
            );

            setCodes({ associations, enterprises, shops, roleCodes });
            setUserData(data);
          } catch (error) {
            window.cerp.dialog.error(
              '사용자 조회 실패',
              `[${error?.code}] ${error?.message}`
            );
          }
        }
      }

      setLoading(false);
    })();
  }, [
    isModified,
    myAssociationId,
    myEnterpriseId,
    myInfoLoadable.state,
    myShopId,
    setValue,
    userId,
    userInfo,
  ]);

  useEffect(() => {
    if (needReset) {
      setIsAvailable({ loginId: false });
      setOfficialSeals(null);
      reset(getDefaultValues(formField));

      setNeedReset(false);
    }
  }, [getDefaultValues, needReset, reset]);

  useEffect(() => {
    if (userData !== null) {
      setIsAvailable({ loginId: true });

      reset(fillEmptyField(userData));
    }
  }, [userData, reset]);

  return (
    <>
      {(() => {
        switch (myInfoLoadable.state) {
          case 'hasValue':
            const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
            return (
              <Modal.Form
                title={'사용자'}
                loading={loading}
                isModified={isModified}
                onHide={onHide}
                onDeleteConfirm={async () => {
                  try {
                    const { data: user } = await userService.delete(userId);
                    // const user = data;
                    window.cerp.toast.success(
                      '사용자 삭제 완료',
                      `[${user.userName}] 사용자가 삭제되었습니다.`
                    );
                    onHide();
                  } catch (error) {
                    window.cerp.dialog.error(
                      '사용자 삭제 실패',
                      `[${error?.code}] ${error?.message}`
                    );
                  }
                }}
                onSubmitConfirm={handleSubmit((data) => {
                  FormUtil.Async.onSubmit(
                    { formData: data, files: officialSeals },
                    isModified,
                    'user',
                    '사용자',
                    setNeedReset
                  );
                })}
                // saveBtnEnable={!(isAvailable.loginId && isFormComplete)}
                saveBtnEnable={!isAvailable.loginId}
                deleteBtnVisible={checkUserAuth(userInfo, 'DELETE')}
              >
                <form autoComplete="off">
                  <Panel
                    headerTemplate={
                      <div className="p-panel-header">
                        {isModified && checkUserAuth(userInfo, 'DISABLED') ? (
                          <Controlled.InputSwitch
                            control={control}
                            watch={watch}
                            inputData={{
                              inputLabel: formField.filter(
                                (item) => item.code === 'useYn'
                              )[0].title,
                              toggleLabel: {
                                trueLabel: '사용',
                                falseLabel: '미사용',
                              },
                              dataLabel: 'useYn',
                            }}
                            styleClass={'font-semibold'}
                          />
                        ) : (
                          <span className="font-semibold">
                            1. 사용자 기본 정보
                          </span>
                        )}
                      </div>
                    }
                  >
                    <div className="grid">
                      {getFilteredInputData(formField, 'dropdown').map(
                        (item, idx) => (
                          <div
                            key={`col_${idx}`}
                            className="col-12 sm:col-6 lg:col-3"
                          >
                            <Controller
                              control={control}
                              name={item.code}
                              defaultValue={item.defaultValue}
                              rules={{
                                required:
                                  item.required(
                                    _.get(currentValues, 'roleCode')
                                  ) && '필수 입력항목입니다.',
                                validate: item.validate,
                              }}
                              render={({ field, fieldState, formState }) => (
                                <Titled.Dropdown
                                  id={field.name}
                                  {...item}
                                  {...field}
                                  required={item.required(
                                    _.get(currentValues, 'roleCode')
                                  )}
                                  showFilter
                                  options={codes[item.dataKey]}
                                  placeholder={
                                    loading ? (
                                      <>
                                        <i className="pi pi-spin pi-spinner m-0 mr-2" />
                                        조회하고 있습니다...
                                      </>
                                    ) : (
                                      item.placeholder
                                    )
                                  }
                                  onChange={async (value) => {
                                    await handleCodeDropdown(myInfo, {
                                      selectedAssociationId:
                                        item.dataKey === 'associations'
                                          ? value
                                          : _.get(
                                              currentValues,
                                              'associationId'
                                            ),
                                      selectedEnterpriseId:
                                        item.dataKey === 'enterprises'
                                          ? value
                                          : _.get(
                                              currentValues,
                                              'enterpriseId'
                                            ),
                                      selectedShopId:
                                        item.dataKey === 'shops'
                                          ? value
                                          : _.get(currentValues, 'shopId'),
                                      selectedRoleCode:
                                        item.dataKey === 'roleCodes'
                                          ? value
                                          : undefined,
                                    });
                                  }}
                                  error={formState.errors[field.name]}
                                  disabled={
                                    (item.dataKey !== 'roleCodes' &&
                                      (!(
                                        (item.dataKey === 'associations' &&
                                          _.includes(
                                            [UserPosition.Erp],
                                            myUserPosition
                                          )) ||
                                        (item.dataKey === 'enterprises' &&
                                          _.includes(
                                            [
                                              UserPosition.Erp,
                                              UserPosition.Association,
                                            ],
                                            myUserPosition
                                          )) ||
                                        (item.dataKey === 'shops' &&
                                          _.includes(
                                            [
                                              UserPosition.Erp,
                                              UserPosition.Association,
                                              UserPosition.Enterprise,
                                            ],
                                            myUserPosition
                                          ))
                                      ) ||
                                        !_.isEmpty(userData))) ||
                                    (item.dataKey === 'roleCodes' &&
                                      _.get(currentValues, 'roleCode') ===
                                        UserRoleType.C_ADM.value)
                                  }
                                />
                              )}
                            />
                          </div>
                        )
                      )}
                      {getFilteredInputData(formField, 'userBasicInfo').map(
                        (item, idx) => (
                          <div
                            key={`col_${idx}`}
                            className="col-12 sm:col-6 lg:col-3"
                          >
                            <Controlled.InputText
                              item={item}
                              key={idx}
                              control={control}
                              setValue={setValue}
                            />
                          </div>
                        )
                      )}
                      <div className="col-12 sm:col-6 lg:col-3">
                        <div className="field m-0">
                          <label>소속일</label>
                          <Controller
                            control={control}
                            name="joinDate"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Calendar
                                id={field.name}
                                {...field}
                                dateFormat="yy-mm-dd"
                                showButtonBar
                                showIcon
                                mask="9999-99-99"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={`${MIN_YEAR}:${today.year() + 1}`}
                                touchUI={touchUI}
                                maxDate={endDate}
                                value={startDate}
                                className="w-full"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-12 sm:col-6 lg:col-3 pb-0">
                        <div className="field m-0">
                          <label>직인</label>
                          <div className="cm-stamp bg-transparent flex flex-auto align-items-start justify-content-start gap-2">
                            {!!currentValues?.officialSealURL ? (
                              <Image
                                src={getOfficialSealPath(
                                  officialSeals,
                                  _.get(currentValues, 'officialSealURL')
                                )}
                                alt={
                                  officialSeals !== null
                                    ? officialSeals[0].name
                                    : ''
                                }
                                preview={officialSeals !== null}
                                imageClassName="border-round border-1 border-200"
                              />
                            ) : (
                              <div
                                className="flex align-items-center justify-content-center border-round border-1 border-200 w-3"
                                style={{ height: '32px' }}
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: '#ced4da' }}
                                />
                              </div>
                            )}
                            <div
                              {...getRootProps({
                                className: 'w-full',
                                style: { wordBreak: 'keep-all' },
                              })}
                            >
                              <input {...getInputProps()} />
                              <Button
                                type="button"
                                label="이미지 선택"
                                icon="pi pi-image"
                                className="w-full p-button-outlined"
                              />
                              {/*<div className="flex flex-auto align-items-center justify-content-center gap-2">*/}
                              {/*  <i className="pi pi-arrow-circle-down" style={{fontSize: '1.5rem'}} />*/}
                              {/*  직인 이미지 선택*/}
                              {/*</div>*/}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 sm:col-6 lg:col-3">
                        <Controller
                          control={control}
                          name="certificateType"
                          defaultValue={'0'}
                          render={({ field, fieldState, formState }) => (
                            <Titled.Dropdown
                              id={field.name}
                              title="자격증구분"
                              {...field}
                              options={[
                                { label: '없음', value: '0' },
                                { label: '자동차정비기능사', value: '1' },
                                { label: '자동차정비기사', value: '2' },
                                { label: '자동차정비기능장', value: '3' },
                              ]}
                              placeholder="없음"
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 sm:col-6 lg:col-3">
                        <Controller
                          control={control}
                          name="certificateNumber"
                          defaultValue=""
                          render={({ field, fieldState, formState }) => (
                            <Titled.InputText
                              id={field.name}
                              title="자격증번호"
                              {...field}
                              disabled={currentValues['certificateType'] === 0}
                              onChange={(e) => {
                                const v = e.target.value.slice(0, 12);
                                field.onChange(v);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Panel>

                  {/* <Panel header="2. 로그인 계정 정보" className="pt-3"> */}
                  <Panel
                    headerTemplate={
                      <div className="p-panel-header flex justify-content-between">
                        <span
                          className="font-semibold"
                          style={{
                            wordBreak: 'keep-all',
                          }}
                        >
                          2. 로그인 계정 정보
                        </span>
                        {isModified &&
                          checkResetPwAuth(myInfoLoadable.contents) && (
                            <>
                              <Button
                                type="button"
                                label="비밀번호 초기화"
                                icon="pi"
                                className="p-button-danger h-auto"
                                onClick={(e) => setShowConfirmPopup(true)}
                              />

                              {/* 비밀번호 초기화 팝업 */}
                              <Dialog
                                visible={showConfirmPopup}
                                header={
                                  <div>
                                    <i className="pi pi-lock mr-2 text-xl font-bold" />
                                    <span>비밀번호 초기화</span>
                                  </div>
                                }
                                onHide={() => setShowConfirmPopup(false)}
                              >
                                <div className="flex flex-column justify-content-center gap-3">
                                  <Password
                                    id="reset-password"
                                    placeholder="영문 + 숫자 조합 4자리 이상"
                                    value={newPassword}
                                    onChange={(e) => {
                                      const value = CommonUtil.Formatter[
                                        'password'
                                      ](e.target.value);

                                      console.log(value);
                                      setNewPassword(e.target.value);
                                    }}
                                    feedback={false}
                                    autoFocus
                                  />
                                  <small className="p-error">
                                    {passwordErrorMsg}
                                  </small>
                                  <div className="button_wrapper flex justify-content-center gap-1">
                                    <Button
                                      label="뒤로 가기"
                                      className="p-button-outlined w-full"
                                      onClick={(e) =>
                                        setShowConfirmPopup(false)
                                      }
                                    />
                                    <Button
                                      label="확인"
                                      className="w-full"
                                      onClick={async (e) => {
                                        const [isValid, errorMsg] =
                                          checkPwValidation(newPassword);

                                        if (isValid) {
                                          await resetPw(newPassword);
                                        } else {
                                          setPasswordErrorMsg(errorMsg);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Dialog>
                            </>
                          )}
                      </div>
                    }
                    className="pt-3"
                  >
                    <div className="grid">
                      <div className="col-12 sm:col-6 lg:col-3">
                        <Controlled.ValidateInputText
                          id={userId}
                          control={control}
                          trigger={trigger}
                          currentValues={currentValues}
                          isAvailable={isAvailable}
                          setIsAvailable={setIsAvailable}
                          setValue={setValue}
                          getValues={getValues}
                          inputData={{
                            inputLabel: formField.filter(
                              (item) => item.code === 'loginId'
                            )[0].title,
                            dataLabel: 'loginId',
                          }}
                          inputConfig={{
                            placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                            autoComplete: 'new-loginId',
                          }}
                          rules={{
                            required: '필수 입력 항목입니다.',
                            // pattern: {
                            //   value: CommonUtil.Pattern['login'],
                            //   message: '유효하지 않은 포맷입니다.',
                            // },
                          }}
                        />
                      </div>

                      {!isModified &&
                        getFilteredInputData(formField, 'password').map(
                          (item, idx) => (
                            <div
                              key={`col_${idx}`}
                              className="col-12 sm:col-6 lg:col-3"
                            >
                              <Controlled.InputPassword
                                item={item}
                                key={idx}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                inputConfig={{
                                  autoComplete: 'chrome-off',
                                }}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Panel>
                </form>
              </Modal.Form>
            );

          case 'loading':
            return <ProgressBar mode="indeterminate" />;

          case 'hasError':
            return null;

          default:
        }
      })()}
    </>
  );
};
