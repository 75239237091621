import { CommonUtil } from '../utils/commonUtil';
import _ from 'lodash';
import dayjs from 'dayjs';

const CERTIFICATE_DATAS = [
  {
    name: 'inspectorName',
    objName: 'inspector',
    fieldName: 'enterpriseName',
    title: '점검업체이름',
    insuranceCompanyCode: ['10', '13', '69'], // DB, 캐롯
    pos: {
      // insuranceCode: [top, left]
      10: [235, 158],
      13: [323, 230],
      69: [258, 465],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.enterpriseName');
    },
  },
  {
    name: 'shopName',
    objName: 'inspector',
    fieldName: 'shopName',
    title: '점검장이름',
    insuranceCompanyCode: ['10', '13', '69'], // DB
    pos: {
      10: [258, 158],
      13: [323, 470],
      69: [258, 515],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.shopName');
    },
  },
  {
    name: 'VIN',
    objName: 'checkData',
    fieldName: 'VIN',
    title: '차대번호전체',
    insuranceCompanyCode: ['10', '13', '69'], // DB, 캐롯
    pos: {
      10: [380, 485],
      13: [345, 150],
      69: [278, 465],
    },
    getValue: (totalValues) => {
      if (_.get(totalValues, 'checkData.VIN').length === 17) {
        return _.get(totalValues, 'checkData.VIN');
      } else {
        return (
          _.get(totalValues, 'vehicleInformation.VIN') +
          _.get(totalValues, 'vehicleInformation.vinRemain')
        );
      }
    },
  },
  {
    name: 'statementNumber',
    objName: 'checkData',
    fieldName: 'PRFOMNC_CHCK_NO',
    title: '성능점검번호',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: [345, 400],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.PRFOMNC_CHCK_NO');
    },
  },
  {
    name: 'CNM',
    objName: 'vehicleInformation',
    fieldName: 'CNM',
    title: '차량명',
    insuranceCompanyCode: ['10'], //KB
    pos: {
      10: [405, 158],
    },
    getValue: (totalValues) => {
      return `${_.get(totalValues, 'vehicleInformation.CNM')}${_.get(
        totalValues,
        'vehicleInformation.cnmDetail'
      )}`;
    },
  },
  {
    name: 'CHCK_DE',
    objName: 'checkData',
    fieldName: 'CHCK_DE',
    title: '성능점검일자',
    insuranceCompanyCode: ['10', '13'], // KB, DB
    pos: {
      10: [405, 485],
      13: [345, 610],
    },
    getValue: (totalValues) => {
      return dayjs(_.get(totalValues, 'checkData.CHCK_DE')).format(
        'YYYY-MM-DD'
      );
    },
  },

  {
    name: 'VHRNO',
    objName: 'checkData',
    fieldName: 'VHRNO',
    title: '차량번호',
    insuranceCompanyCode: ['10', '13', '69'], // KB, DB, 캐롯
    pos: {
      10: [380, 158],
      13: [365, 150],
      69: [278, 155],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.VHRNO');
    },
  },

  {
    name: 'insurancePolicyNumber',
    objName: 'paymentInformation',
    fieldName: 'insurancePolicyNumber',
    title: '보험증권번호',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: [365, 400],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'insurancePolicyNumber');
    },
  },

  {
    name: 'paidInsurancePriceAmount',
    objName: 'paymentInformation',
    fieldName: 'paidInsurancePriceAmount',
    title: '보험료',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: [365, 610],
    },
    getValue: (totalValues) => {
      return `${CommonUtil.Unit.format(
        _.get(totalValues, 'paymentInformation.insurancePrice') +
          _.get(totalValues, 'paymentInformation.insurancePriceVAT')
      )}원`;
    },
  },
  {
    name: 'insuranceType',
    title: '보험종목',
    insuranceCompanyCode: ['69'], // 캐롯
    pos: {
      69: [258, 155],
    },
    getValue: (totalValues) => {
      return '자동차 성능∙상태점검 책임보험';
    },
  },
  {
    name: 'customerServiceCenterNumber',
    title: '상담번호',
    insuranceCompanyCode: ['10', '13'], // KB, DB
    pos: {
      10: [],
      13: [],
    },
    getValue: (totalValues) => {
      const numbers = {
        10: '',
        13: '1600-3528',
      };
      return _.get(
        numbers,
        _.get(totalValues, 'paymentInformation.insuranceCompany')
      );
    },
  },
];

export { CERTIFICATE_DATAS };
