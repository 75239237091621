import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import _ from 'lodash';

import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { compressImages } from '../../utils/compressUtil';
import FileState from '../../enums/FileState';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Claim from '../../services/ClaimService';
const CustomerDocument = ({
  fileItems,
  setFileItems,
  deleteImgs,
  setDeleteImgs,
  copyFile,
  setShowSmsType,
  setShowSmsModal,
  setSmsData,
  smsData,
  carDetailData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  let { dealer } = useParams();
  const dealerUser = dealer?.split('=')[1] === 'true' ? true : false;

  const deleteFile = (e) => {
    const trashFile = fileItems.filter(
      (el) => el.documentCode === e.target.name
    );
    setDeleteImgs(deleteImgs.concat(trashFile));

    const deletedList = fileItems.filter(
      (el) => el.documentCode !== e.target.name
    );
    setFileItems(deletedList);
  };

  const frontImg = fileItems?.filter((el) => el.documentCode === 'C_FRONT');
  const dashboardImg = fileItems?.filter(
    (el) => el.documentCode === 'C_DASHBOARD'
  );
  const contractImg = fileItems?.filter(
    (el) => el.documentCode === 'C_CONTRACT'
  );
  const certificateImg = fileItems?.filter(
    (el) => el.documentCode === 'C_CERTIFICATE'
  );
  const check1Img = fileItems?.filter((el) => el.documentCode === 'S_CHECK1');
  const check2Img = fileItems?.filter((el) => el.documentCode === 'S_CHECK2');
  const saleseContractImg = fileItems?.filter(
    (el) => el.documentCode === 'S_CONTRACT'
  );
  const insuranceImg = fileItems?.filter(
    (el) => el.documentCode === 'S_INSURANCE'
  );

  async function getEncodedImgUrl(imageUrl) {
    const url = _.startsWith(imageUrl, 'http')
      ? imageUrl
      : process.env.REACT_APP_S3_BASE_URL + imageUrl;
    const { data: encodedUrl } = await Claim.getBase64Url(url);
    return encodedUrl;
  }

  const saveFilesAsZip = (title) => {
    const folderName =
      carDetailData?.performanceCheckData.VHRNO +
      `${title === '고객' ? '_고객접수서류' : '_매매상사접수서류'}`;
    const files =
      title === '고객'
        ? [...frontImg, ...dashboardImg, ...contractImg, ...certificateImg]
        : [...check1Img, ...check2Img, ...saleseContractImg, ...insuranceImg];
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const remoteZips = files.map(async (file) => {
      const fetchedFile = await getEncodedImgUrl(file.filePath);
      return fetchedFile;
    });
    remoteZips.forEach((imgBlob, index) => {
      imagesFolder.file(`img_${index + 1}.jpg`, imgBlob, {
        blob: true,
        base64: true,
      });
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const S_CHECK1 = useRef(null);
  const S_CHECK2 = useRef(null);
  const S_CONTRACT = useRef(null);
  const S_INSURANCE = useRef(null);

  const handleClick = (e) => {
    switch (e.target.id) {
      case '성능기록부(1)':
        S_CHECK1.current.click();
        break;
      case '성능기록부(2)':
        S_CHECK2.current.click();
        break;
      case '전산 매도용 계약서':
        S_CONTRACT.current.click();
        break;
      case '자동차보험 가입증명서':
        S_INSURANCE.current.click();
        break;
      default:
        break;
    }
  };

  const uploadFiles = async (acceptedFiles, title) => {
    // console.log('title', title);
    setLoading(true);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    const files = Object.values(acceptedFiles);
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    const compressedImages = await compressImages(files, options);
    compressedImages.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    const code = DOC_TYPE.find((el) => el.label === title)?.value;
    const partCodeAttachedFiles = _.reduce(
      compressedImages,
      (res, v) => {
        res.push({
          documentCode: code,
          file: v,
          state: FileState.Inserted,
        });
        return res;
      },
      []
    );

    setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    setLoading(false);
    window.cerp.toast.info('등록이 완료 되었습니다.');
  };

  return (
    <>
      <Panel header="2. 서류접수 (고객/매매상사)" className="pt-3">
        <div className="grid">
          <div className="col-6">
            {!dealerUser && (
              <>
                <Card
                  header={
                    <div className="flex justify-content-between p-3">
                      <span className="p-card-title">고객</span>
                      <div>
                        <Button.Default
                          // icon="pi pi-send"
                          label="매매상사 SMS 전송"
                          className="p-button-outlined"
                          onClick={() => {
                            setShowSmsModal(true);
                            setShowSmsType('DEALER_UPLOAD_URL');
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  <div className="field grid">
                    <div className="col-12">
                      <div className="flex justify-content-between align-items-center h-3rem">
                        <div className="col font-bold">차량전면사진</div>
                        <div>
                          {frontImg?.length > 0
                            ? frontImg.length + ' 장'
                            : '접수 대기중'}
                        </div>
                        <div>
                          {frontImg?.length > 0 && (
                            <>
                              <Button.Default
                                label="보기"
                                icon="pi pi-window-maximize"
                                className="p-button-text"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'images',
                                    JSON.stringify(frontImg)
                                  );
                                  CommonUtil.Window.popup(
                                    '/compensation/accident/claim/viewer',
                                    '',
                                    window.innerWidth * 0.9,
                                    window.innerHeight * 0.9
                                  );
                                }}
                              />
                              <Button.Default
                                icon="pi pi-trash"
                                label=""
                                className="p-button-text"
                                onClick={(e) => {
                                  deleteFile(e);
                                }}
                                name="C_FRONT"
                              />
                            </>
                          )}
                        </div>
                        {!frontImg?.length && (
                          <div
                            onClick={(e) => {
                              setCurTitle(e.target.id);
                              setShowModal(true);
                            }}
                            className="flex justify-content-center cursor-pointer"
                          >
                            <i
                              style={{
                                color: 'var(--primary-color)',
                                width: '28px',
                              }}
                              className="pi pi-upload flex justify-content-center"
                              id="차량전면사진"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-content-between align-items-center h-3rem">
                        <div className="col font-bold">
                          현재 기준 계기판 사진
                        </div>
                        <div>
                          {dashboardImg?.length > 0
                            ? dashboardImg.length + ' 장'
                            : '접수 대기중'}
                        </div>
                        <div>
                          {dashboardImg?.length > 0 && (
                            <>
                              <Button.Default
                                label="보기"
                                icon="pi pi-window-maximize"
                                className="p-button-text"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'images',
                                    JSON.stringify(dashboardImg)
                                  );
                                  CommonUtil.Window.popup(
                                    '/compensation/accident/claim/viewer',
                                    '',
                                    window.innerWidth * 0.9,
                                    window.innerHeight * 0.9
                                  );
                                }}
                              />
                              <Button.Default
                                icon="pi pi-trash"
                                label=""
                                className="p-button-text"
                                onClick={(e) => {
                                  deleteFile(e);
                                }}
                                name="C_DASHBOARD"
                              />
                            </>
                          )}
                        </div>
                        {!dashboardImg?.length && (
                          <div
                            onClick={(e) => {
                              setCurTitle(e.target.id);
                              setShowModal(true);
                            }}
                            className="flex justify-content-center cursor-pointer"
                          >
                            <i
                              style={{
                                color: 'var(--primary-color)',
                                width: '28px',
                              }}
                              className="pi pi-upload flex justify-content-center"
                              id="현재 기준 계기판 사진"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-content-between align-items-center h-3rem">
                        <div className="col font-bold">매매계약서</div>
                        <div>
                          {contractImg?.length > 0
                            ? contractImg.length + ' 장'
                            : '접수 대기중'}
                        </div>
                        <div>
                          {contractImg?.length > 0 && (
                            <>
                              <Button.Default
                                label="보기"
                                icon="pi pi-window-maximize"
                                className="p-button-text"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'images',
                                    JSON.stringify(contractImg)
                                  );
                                  CommonUtil.Window.popup(
                                    '/compensation/accident/claim/viewer',
                                    '',
                                    window.innerWidth * 0.9,
                                    window.innerHeight * 0.9
                                  );
                                }}
                              />
                              <Button.Default
                                icon="pi pi-trash"
                                label=""
                                className="p-button-text"
                                onClick={(e) => {
                                  deleteFile(e);
                                }}
                                name="C_CONTRACT"
                              />
                            </>
                          )}
                        </div>
                        {!contractImg?.length && (
                          <div
                            onClick={(e) => {
                              setCurTitle(e.target.id);
                              setShowModal(true);
                            }}
                            className="flex justify-content-center cursor-pointer"
                          >
                            <i
                              style={{
                                color: 'var(--primary-color)',
                                width: '28px',
                              }}
                              className="pi pi-upload flex justify-content-center"
                              id="매매계약서"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-content-between align-items-center h-3rem">
                        <div className="col font-bold">차량등록증</div>
                        <div>
                          {certificateImg?.length > 0
                            ? certificateImg.length + ' 장'
                            : '접수 대기중'}
                        </div>
                        <div>
                          {certificateImg?.length > 0 && (
                            <>
                              <Button.Default
                                label="보기"
                                icon="pi pi-window-maximize"
                                className="p-button-text"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'images',
                                    JSON.stringify(certificateImg)
                                  );
                                  CommonUtil.Window.popup(
                                    '/compensation/accident/claim/viewer',
                                    '',
                                    window.innerWidth * 0.9,
                                    window.innerHeight * 0.9
                                  );
                                }}
                              />
                              <Button.Default
                                icon="pi pi-trash"
                                label=""
                                className="p-button-text"
                                onClick={(e) => {
                                  deleteFile(e);
                                }}
                                name="C_CERTIFICATE"
                              />
                            </>
                          )}
                        </div>
                        {!certificateImg?.length && (
                          <div
                            onClick={(e) => {
                              setCurTitle(e.target.id);
                              setShowModal(true);
                            }}
                            className="flex justify-content-center cursor-pointer"
                          >
                            <i
                              style={{
                                color: 'var(--primary-color)',
                                width: '28px',
                              }}
                              className="pi pi-upload flex justify-content-center"
                              id="차량등록증"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="flex justify-content-end align-items-center h-3rem mt-2">
                  {!dealerUser && (
                    <Button.Default
                      className="p-button-outlined"
                      icon="pi pi-download"
                      label="고객서류 다운로드"
                      onClick={() => saveFilesAsZip('고객')}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className={dealerUser ? 'col-12' : 'col-6'}>
            <Card
              header={
                <div className="flex justify-content-between p-3">
                  <span className="p-card-title">매매상사</span>
                  {!dealerUser && (
                    <Button.Default
                      // icon="pi pi-send"
                      label="서류접수 알림 재전송"
                      className="p-button-outlined"
                      onClick={() => {
                        setShowSmsModal(true);
                        setShowSmsType('DEALER_UPLOAD_URL');
                      }}
                    />
                  )}
                </div>
              }
            >
              <div className="field grid">
                <div className="col-12">
                  <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                    <div className="col font-bold">성능기록부 (1)</div>
                    <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                      {check1Img?.length > 0
                        ? check1Img.length + ' 장'
                        : '접수 대기중'}
                    </div>
                    <div>
                      {check1Img?.length > 0 && (
                        <>
                          <Button.Default
                            label="보기"
                            icon="pi pi-window-maximize"
                            className="p-button-text"
                            onClick={() => {
                              sessionStorage.setItem(
                                'images',
                                JSON.stringify(check1Img)
                              );
                              CommonUtil.Window.popup(
                                '/compensation/accident/claim/viewer',
                                '',
                                window.innerWidth * 0.9,
                                window.innerHeight * 0.9
                              );
                            }}
                          />
                          <Button.Default
                            icon="pi pi-trash"
                            className="p-button-text"
                            onClick={(e) => {
                              deleteFile(e);
                            }}
                            name="S_CHECK1"
                          />
                        </>
                      )}
                    </div>
                    {!check1Img?.length && (
                      <>
                        <div
                          onClick={
                            !dealerUser
                              ? (e) => {
                                  setCurTitle(e.target.id);
                                  setShowModal(true);
                                }
                              : (e) => handleClick(e)
                          }
                          className="flex justify-content-center cursor-pointer"
                        >
                          <i
                            style={{
                              color: 'var(--primary-color)',
                              width: '28px',
                            }}
                            className="pi pi-upload flex justify-content-center"
                            id="성능기록부(1)"
                          />
                        </div>
                        <input
                          ref={S_CHECK1}
                          className="hidden"
                          type="file"
                          accept="image/png, image/jpeg"
                          multiple
                          onChange={(e) =>
                            uploadFiles(e.target.files, '성능기록부(1)')
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                    <div className="col font-bold">성능기록부 (2)</div>
                    <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                      {check2Img?.length > 0
                        ? check2Img.length + ' 장'
                        : '접수 대기중'}
                    </div>
                    <div>
                      {check2Img?.length > 0 && (
                        <>
                          <Button.Default
                            label="보기"
                            icon="pi pi-window-maximize"
                            className="p-button-text"
                            onClick={() => {
                              sessionStorage.setItem(
                                'images',
                                JSON.stringify(check2Img)
                              );
                              CommonUtil.Window.popup(
                                '/compensation/accident/claim/viewer',
                                '',
                                window.innerWidth * 0.9,
                                window.innerHeight * 0.9
                              );
                            }}
                          />
                          <Button.Default
                            icon="pi pi-trash"
                            label=""
                            className="p-button-text"
                            onClick={(e) => {
                              deleteFile(e);
                            }}
                            name="S_CHECK2"
                          />
                        </>
                      )}
                    </div>
                    {!check2Img?.length && (
                      <>
                        <div
                          onClick={
                            !dealerUser
                              ? (e) => {
                                  setCurTitle(e.target.id);
                                  setShowModal(true);
                                }
                              : (e) => handleClick(e)
                          }
                          className="flex justify-content-center cursor-pointer"
                        >
                          <i
                            style={{
                              color: 'var(--primary-color)',
                              width: '28px',
                            }}
                            className="pi pi-upload flex justify-content-center"
                            id="성능기록부(2)"
                          />
                        </div>
                        <input
                          ref={S_CHECK2}
                          className="hidden"
                          type="file"
                          accept="image/png, image/jpeg"
                          multiple
                          onChange={(e) =>
                            uploadFiles(e.target.files, '성능기록부(2)')
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                    <div className="col font-bold">전산 매도용 계약서</div>
                    <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                      {saleseContractImg?.length > 0
                        ? saleseContractImg.length + ' 장'
                        : '접수 대기중'}
                    </div>
                    <div>
                      {saleseContractImg?.length > 0 && (
                        <>
                          <Button.Default
                            label="보기"
                            icon="pi pi-window-maximize"
                            className="p-button-text"
                            onClick={() => {
                              sessionStorage.setItem(
                                'images',
                                JSON.stringify(saleseContractImg)
                              );
                              CommonUtil.Window.popup(
                                '/compensation/accident/claim/viewer',
                                '',
                                window.innerWidth * 0.9,
                                window.innerHeight * 0.9
                              );
                            }}
                          />
                          <Button.Default
                            icon="pi pi-trash"
                            label=""
                            className="p-button-text"
                            onClick={(e) => {
                              deleteFile(e);
                            }}
                            name="S_CONTRACT"
                          />
                        </>
                      )}
                    </div>
                    {!saleseContractImg?.length && (
                      <>
                        <div
                          onClick={
                            !dealerUser
                              ? (e) => {
                                  setCurTitle(e.target.id);
                                  setShowModal(true);
                                }
                              : (e) => handleClick(e)
                          }
                          className="flex justify-content-center cursor-pointer"
                        >
                          <i
                            style={{
                              color: 'var(--primary-color)',
                              width: '28px',
                            }}
                            className="pi pi-upload flex justify-content-center"
                            id="전산 매도용 계약서"
                          />
                        </div>
                        <input
                          ref={S_CONTRACT}
                          className="hidden"
                          type="file"
                          accept="image/png, image/jpeg"
                          multiple
                          onChange={(e) =>
                            uploadFiles(e.target.files, '전산 매도용 계약서')
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                    <div className="col font-bold">자동차보험 가입증명서</div>
                    <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                      {insuranceImg?.length > 0
                        ? insuranceImg.length + ' 장'
                        : '접수 대기중'}
                    </div>
                    <div>
                      {insuranceImg?.length > 0 && (
                        <>
                          <Button.Default
                            label="보기"
                            icon="pi pi-window-maximize"
                            className="p-button-text"
                            onClick={() => {
                              sessionStorage.setItem(
                                'images',
                                JSON.stringify(insuranceImg)
                              );
                              CommonUtil.Window.popup(
                                '/compensation/accident/claim/viewer',
                                '',
                                window.innerWidth * 0.9,
                                window.innerHeight * 0.9
                              );
                            }}
                          />
                          <Button.Default
                            icon="pi pi-trash"
                            label=""
                            className="p-button-text"
                            onClick={(e) => {
                              deleteFile(e);
                            }}
                            name="S_INSURANCE"
                          />
                        </>
                      )}
                    </div>
                    {!insuranceImg?.length && (
                      <>
                        <div
                          onClick={
                            !dealerUser
                              ? (e) => {
                                  setCurTitle(e.target.id);
                                  setShowModal(true);
                                }
                              : (e) => handleClick(e)
                          }
                          className="flex justify-content-center cursor-pointer"
                        >
                          <i
                            style={{
                              color: 'var(--primary-color)',
                              width: '28px',
                            }}
                            className="pi pi-upload flex justify-content-center"
                            id="자동차보험 가입증명서"
                          />
                        </div>
                        <input
                          ref={S_INSURANCE}
                          className="hidden"
                          type="file"
                          accept="image/png, image/jpeg"
                          multiple
                          onChange={(e) =>
                            uploadFiles(e.target.files, '자동차보험 가입증명서')
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <div className="flex justify-content-end align-items-center h-3rem mt-2">
              {!dealerUser && (
                <Button.Default
                  className="p-button-outlined"
                  icon="pi pi-download"
                  label="매매상사서류 다운로드"
                  onClick={() => saveFilesAsZip('매매')}
                />
              )}
            </div>
          </div>
        </div>
      </Panel>

      {showModal && curTitle && (
        <ImagesPopup
          title={curTitle}
          onHide={() => setShowModal(!showModal)}
          setFileItems={setFileItems}
          copyFile={copyFile}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default CustomerDocument;

const ImagesPopup = ({
  onHide,
  images,
  title,
  setFileItems,
  copyFile,
  loading,
  setLoading,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const code = DOC_TYPE.find((el) => el.label === title).value;
      // const findCode = copyFile.find((el) => el.documentCode === code);
      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: code,
            file: v,
            // state: findCode ? FileState.Updated : FileState.Inserted,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
      setLoading(false);
      onHide();
      window.cerp.toast.info('등록이 완료 되었습니다.');
    },
  });
  return (
    <>
      <Modal.Default
        header={`${title} 등록`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  사진을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};

const DOC_TYPE = [
  {
    value: 'C_FRONT',
    label: '차량전면사진',
  },
  {
    value: 'C_DASHBOARD',
    label: '현재 기준 계기판 사진',
  },
  {
    value: 'C_CONTRACT',
    label: '매매계약서',
  },
  {
    value: 'C_CERTIFICATE',
    label: '차량등록증',
  },
  {
    value: 'S_CHECK1',
    label: '성능기록부(1)',
  },
  {
    value: 'S_CHECK2',
    label: '성능기록부(2)',
  },
  {
    value: 'S_CONTRACT',
    label: '전산 매도용 계약서',
  },
  {
    value: 'S_INSURANCE',
    label: '자동차보험 가입증명서',
  },
];
