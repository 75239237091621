import { AssociationService } from './AssociationService';
import { AuthService } from './AuthService';
import { BaseCheckPriceService } from './BaseCheckPriceService';
import { BaseInsurancePriceService } from './BaseInsurancePriceService';
import { BaseService } from './BaseService';
import { BoardService } from './BoardService';
import { CheckPriceService } from './CheckPriceService';
import { ClaimService } from './ClaimService';
import { CodeService } from './CodeService';
import { CountryService } from './CountryService';
import { CustomerCompanyService } from './CustomerCompanyService';
import { CustomerService } from './CustomerService';
import { DealerService } from './DealerService';
import { EnterpriseService } from './EnterpriseService';
import { ErpActionService } from './ErpActionService';
import { EventService } from './EventService';
import { KakaoService } from './KakaoService';
import { MolitService } from './MolitService';
import { MyInfoService } from './MyInfoService';
import { NodeService } from './NodeService';
import { PartnerService } from './PartnerService';
import { PerformanceCheckPaymentService } from './PerformanceCheckPaymentService';
import { PerformanceCheckReceivableService } from './PerformanceCheckReceivableService';
import { PerformanceCheckService } from './PerformanceCheckService';
import { PhotoService } from './PhotoService';
import { PriceService } from './PriceService';
import { ProductService } from './ProductService';
import { QNAService } from './QNAService';
import { SearchService } from './SearchService';
import { ShopCheckPriceService } from './ShopCheckPriceService';
import { ShopInsurancePriceService } from './ShopInsurancePriceService';
import { ShopService } from './ShopService';
import { UserService } from './UserService';

export const ServiceProvider = {
  association: AssociationService.getInstance(),
  auth: AuthService.getInstance(),
  baseCheckPrice: BaseCheckPriceService.getInstance(),
  baseInsurancePrice: BaseInsurancePriceService.getInstance(),
  base: BaseService.getInstance(),
  board: BoardService.getInstance(),
  checkPrice: CheckPriceService.getInstance(),
  code: CodeService.getInstance(),
  country: CountryService.getInstance(),
  customerCompany: CustomerCompanyService.getInstance(),
  customer: CustomerService.getInstance(),
  dealer: DealerService.getInstance(),
  enterprise: EnterpriseService.getInstance(),
  erpAction: ErpActionService.getInstance(),
  event: EventService.getInstance(),
  kakao: KakaoService.getInstance(),
  molit: MolitService.getInstance(),
  myInfo: MyInfoService.getInstance(),
  node: NodeService.getInstance(),
  partner: PartnerService.getInstance(),
  performanceCheckPayment: PerformanceCheckPaymentService.getInstance(),
  performanceCheckReceivable: PerformanceCheckReceivableService.getInstance(),
  performanceCheck: PerformanceCheckService.getInstance(),
  photo: PhotoService.getInstance(),
  price: PriceService.getInstance(),
  product: ProductService.getInstance(),
  search: SearchService.getInstance(),
  shopCheckPrice: ShopCheckPriceService.getInstance(),
  shopInsurancePrice: ShopInsurancePriceService.getInstance(),
  shop: ShopService.getInstance(),
  user: UserService.getInstance(),
  qna: QNAService.getInstance(),
  claim: ClaimService.getInstance(),
};
