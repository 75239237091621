import { DefaultValue, selector } from 'recoil';
import { myInfoState, userState, noticeState } from './atoms';

import { ServiceProvider } from '../services';

export const userStateSelector = selector({
  key: 'userState/selector',
  get: ({ get }) => get(),
  set: ({ set, reset }, newUserState) => {
    console.log('newUserState', newUserState);

    if (newUserState instanceof DefaultValue) {
      reset(userState);
    } else {
      set(userState, newUserState);
    }
  },
});

export const myInfoSelector = selector({
  key: 'myInfo/selector',
  get: async ({ get }) => {
    if (get(userState)['accessToken'] !== null) {
      const myInfoService = ServiceProvider.myInfo;
      const { data, error } = await myInfoService.myInfo();

      if (data) return data;
      return null;
    } else return null;
  },
  set: ({ set, reset }, newMyInfo) => {
    if (newMyInfo instanceof DefaultValue) {
      reset(myInfoState);
    } else {
      set(myInfoState, newMyInfo);
    }
  },
});

export const noticeSelector = selector({
  key: 'notice/selector',
  get: ({ get }) => {
    const list = get(noticeState);
    return list.length;
  },
  set: ({ set, reset }, newNotice) => {
    if (newNotice instanceof DefaultValue) {
      reset(noticeState);
    } else {
      set(noticeState, newNotice);
    }
  },
});
