import React, { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { MultiSelect } from 'primereact/multiselect';
import Claim from '../../services/ClaimService';

const Repair = ({
  repairData,
  setRepairData,
  detailData,
  setShowSmsType,
  setShowSmsModal,
}) => {
  const [list, setList] = useState([]);
  const [text, setText] = useState();

  const getDiagnosisType = async () => {
    try {
      const data = await Claim.getDocsType('I');
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDiagnosisType();
  }, []);

  const [findText, setFindText] = useState([]);
  useEffect(() => {
    if (!!repairData.maintenanceCode.length) {
      setFindText(repairData.maintenanceCode);
    }
  }, [list, repairData]);

  return (
    <div>
      <Panel header="5. 수리 정보" className="pt-3">
        <div className="grid">
          <div className="col-12">
            <Card title="수리정보">
              <div className="field grid align-items-center">
                <div className="col-2 font-bold">
                  <label htmlFor="">차량 인도일자</label>
                </div>
                <div className="col">
                  <Calendar
                    value={repairData.date}
                    onChange={(e) =>
                      setRepairData({ ...repairData, date: e.target.value })
                    }
                    dateFormat="yy-mm-dd"
                    showButtonBar
                    showIcon
                    mask="9999-99-99"
                    placeholder="인도일자 선택"
                  />
                </div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-2 font-bold">
                  <label htmlFor="">수리 부위</label>
                </div>
                <div className="col">
                  <MultiSelect
                    placeholder="수리 부위 선택"
                    className="overflow-hidden w-full"
                    filter
                    options={list}
                    optionLabel="label"
                    value={!!findText.length ? findText : text}
                    onChange={(e) => {
                      setRepairData((ps) => ({
                        ...ps,
                        maintenanceCode: e.target.value,
                      }));
                      setText(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="field grid">
                <div className="col-2 font-bold">수리 여부</div>
                <div className="col">
                  <RadioButton
                    inputId="workY"
                    name="customerSearchBy"
                    value="Y"
                    className="mr-2"
                    onChange={(e) =>
                      setRepairData({ ...repairData, workYN: e.target.value })
                    }
                    checked={repairData.workYN === 'Y'}
                  />
                  <label htmlFor="workY">수리</label>
                  <RadioButton
                    inputId="workN"
                    name="customerSearchBy"
                    value="N"
                    className="ml-4 mr-2"
                    onChange={(e) =>
                      setRepairData({ ...repairData, workYN: e.target.value })
                    }
                    checked={repairData.workYN === 'N'}
                  />
                  <label htmlFor="workN">미 수리</label>
                </div>
              </div>
              <div className="field grid mb-0 align-items-center">
                <div className="col-2 font-bold">
                  <label htmlFor="">협의비용</label>
                </div>
                <div className="col-3">
                  <InputText
                    value={repairData.workFee}
                    onChange={(e) =>
                      setRepairData({ ...repairData, workFee: e.target.value })
                    }
                    className=" w-full"
                    placeholder="협의비용 입력"
                  />
                </div>
                <Button
                  label="진단점 수리사진 업로드 SMS 전송"
                  // icon="pi pi-send"
                  className="p-button-outlined mr-1"
                  onClick={() => {
                    setShowSmsModal(true);
                    setShowSmsType('PARTNER_UPLOAD_URL');
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default Repair;
