// import * as Diff from 'deep-object-diff';
import * as ExcelJS from 'exceljs-enhance';

import React, { useEffect, useMemo, useState } from 'react';
import { VEHICLE_TYPE_LEVEL1, YN } from '../../../constants/Constants';

import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import Chance from 'chance';
import CheckPriceItemDialog from './CheckPriceItemDialog';
import CheckPriceSaveConfirmDialog from './CheckPriceSaveConfirmDialog';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ServiceProvider } from '../../../services';
import { SplitButton } from 'primereact/splitbutton';
import UserPosition from '../../../enums/UserPosition';
import _ from 'lodash';
import { columnCheckPriceType } from '../../../utils/dataTableUtil';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import usePrevious from '../../../hooks/usePrevious';

const chance = new Chance();
const codeService = ServiceProvider.code;
const baseCheckPriceService = ServiceProvider.baseCheckPrice;

const BaseCheckPriceDialog = ({
  userInfo,
  checkPriceTable: {
    associationId = 0,
    enterpriseId = 0,
    priceTableId = 0,
    isDeletable = 'N',
  },
  onHide = null,
}) => {
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  const [selectedAssociationId, setSelectedAssociationId] = useState(
    associationId || myAssociationId
  );
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(
    enterpriseId || myEnterpriseId
  );
  const [codes, setCodes] = useState({});

  const [checkPriceTable, setCheckPriceTable] = useState(null);
  const previousCheckPriceTable = usePrevious(checkPriceTable);
  const [currentItem, setCurrentItem] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    delete: false,
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitDone, setIsSubmitDone] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);

      if (acceptedFiles[0]) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(acceptedFiles[0]);
        reader.onload = () => {
          const buffer = reader.result;

          workbook.xlsx.load(buffer).then((workbook) => {
            const worksheet =
              workbook.getWorksheet('점검료양식') ||
              workbook.getWorksheet('점검료테이블');

            if (worksheet) {
              let tableData = [];

              worksheet.eachRow((row, rowIndex) => {
                if (rowIndex > 3) {
                  // exceljs - row.values의 첫번째 데이터가 항상 null임
                  const [, priceType, title, price] = row.values;
                  tableData.push({
                    id: chance.hash(),
                    priceType: _.get(
                      _.find(VEHICLE_TYPE_LEVEL1, { label: priceType }),
                      'value'
                    ),
                    title: title || '이름없음',
                    price: _.toNumber(
                      `${_.isEmpty(_.toString(price)) ? 0 : price}`
                    ),
                  });
                }
              });

              setCheckPriceTable((ps) => ({ ...ps, tableData }));
            } else {
              window.cerp.toast.warn(
                '점검료 양식 오류',
                '올바른 점검료 양식이 아닙니다.'
              );
            }
          });
        };
      }
    },
  });

  async function onSubmit(confirmData) {
    console.log(checkPriceTable, confirmData);
    setLoading(true);

    const requestBody = {
      associationId: selectedAssociationId,
      enterpriseId: selectedEnterpriseId,
      priceTableId,
      ...confirmData,
      tableData: JSON.stringify(_.get(checkPriceTable, 'tableData')),
    };

    console.log(checkPriceTable, requestBody);

    try {
      const { data } = await baseCheckPriceService.register(requestBody);
      window.cerp.toast.success(
        `기본 점검료 테이블 등록 완료`,
        `기본 점검료 테이블을 등록했습니다.`
      );

      const tableData = JSON.parse(data.tableData);
      // console.log(tableData);
      console.log('submit 후 돌려받은 데이터', data);
      setCheckPriceTable({ ...data, tableData: tableData });
      // console.log('checkPriceTable', checkPriceTable);
      setIsSubmitDone(true);
    } catch (error) {
      window.cerp.dialog.error(
        `기본 점검료 테이블 등록 실패`,
        `[${error?.code}] ${error?.message}`
      );
    } finally {
      setLoading(false);
    }
  }

  async function checkStartDate() {
    setLoading(true);

    try {
      const { data } = await baseCheckPriceService.checkStartDate(
        selectedEnterpriseId
      );
      const { minStartDate } = data;
      const current = dayjs();
      const resDate = dayjs(minStartDate);
      const diff = current.diff(resDate);

      if (diff >= 0) {
        const tomorrow = current
          .add(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DD');
        setMinStartDate(tomorrow);
      } else {
        setMinStartDate(minStartDate);
      }
    } catch (error) {
      // 신규 등록일 경우 등록된 데이터가 없고 400 으로 떨어짐
      if (error?.code !== 'p4000002') {
        console.log('[Check Start Date ERROR]', error);
      }
      return;
    }

    setLoading(false);
  }

  async function getAssociationCodes({ preSelectedAssociationId }) {
    const { data } = await codeService.associations();
    const associations = [...data];
    const association =
      (preSelectedAssociationId
        ? _.find(associations, { value: preSelectedAssociationId })
        : _.get(associations, 0)) || {};

    return [associations, _.get(association, 'value'), association];
  }

  async function getEnterpriseCodes({
    associationId = 0,
    preSelectedEnterpriseId,
  }) {
    if (associationId === 0) return [[], 0, {}];

    const { data: enterprises } = await codeService.enterprises([
      associationId,
    ]);
    const enterprise =
      (preSelectedEnterpriseId
        ? _.find(enterprises, { value: preSelectedEnterpriseId })
        : _.get(enterprises, 0)) || {};
    return [enterprises, _.get(enterprise, 'value'), enterprise];
  }

  function initCheckPriceTable() {
    setCheckPriceTable(null);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (priceTableId === 0) {
        const [associations, associationId] = await getAssociationCodes({
          preSelectedAssociationId: myAssociationId,
        });
        const [enterprises, enterpriseId] = await getEnterpriseCodes({
          associationId,
          preSelectedEnterpriseId: myEnterpriseId,
        });

        setCodes({ associations, enterprises });
        setSelectedAssociationId(associationId);
        setSelectedEnterpriseId(enterpriseId);
      } else {
        const { data, error } = await baseCheckPriceService.getData(
          priceTableId
        );

        if (data) {
          const { associationId, enterpriseId } = data;
          const tableData = JSON.parse(data.tableData);

          const [[associations], [enterprises]] = await Promise.all([
            await getAssociationCodes({
              preSelectedAssociationId: associationId,
            }),
            await getEnterpriseCodes({
              associationId,
              preSelectedEnterpriseId: enterpriseId,
            }),
          ]);

          setCodes({ associations, enterprises });
          setSelectedAssociationId(associationId);
          setSelectedEnterpriseId(enterpriseId);
          setCheckPriceTable({ ...data, tableData });
        }

        if (error)
          window.cerp.dialog.error(
            '점검료 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
      }

      setLoading(false);
    })();
  }, [myAssociationId, myEnterpriseId, priceTableId]);

  return (
    <Dialog
      modal
      maximizable
      breakpoints={{ '960px': '90vw' }}
      visible
      style={{ width: '70vw' }}
      onHide={onHide}
      // header="기본 점검료 테이블"
      header={
        priceTableId === 0 ? '점검료 테이블 신규 등록' : '점검료 테이블 수정'
      }
      footer={
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
          {priceTableId === 0 && !isSubmitDone && (
            <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
              <ConfirmDialog
                visible={showConfirmPopup['reset']}
                header="확인"
                message="변경된 내용을 초기화 하시겠습니까?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="네"
                acceptIcon="pi pi-check"
                accept={() => {
                  initCheckPriceTable();
                }}
                rejectLabel="아니오"
                reject={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: false }))
                }
                onHide={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: false }))
                }
              />
              <Button
                id="btn-reset"
                className="p-button-outlined"
                label="초기화"
                icon="pi pi-undo"
                autoFocus
                onClick={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: true }))
                }
              />

              {showConfirmPopup['submit'] && (
                <CheckPriceSaveConfirmDialog
                  isUpdate={checkPriceTable !== null}
                  minStartDate={minStartDate}
                  onHide={(confirmData) => {
                    setShowConfirmPopup((ps) => ({ ...ps, submit: false }));
                    setMinStartDate(null);
                    if (confirmData) {
                      console.log(confirmData);
                      onSubmit(confirmData);
                    }
                  }}
                />
              )}
              <Button
                id="btn-save"
                label="저장"
                icon="pi pi-save"
                autoFocus
                disabled={
                  !(
                    checkPriceTable !== null &&
                    selectedAssociationId > 0 &&
                    selectedEnterpriseId > 0
                  )
                }
                tooltip="점검업체 점검료 테이블을 등록하기 위해서는 소속협회, 점검법인이 모두 등록되어 있어야 합니다."
                tooltipOptions={{
                  position: 'bottom',
                  mouseTrack: true,
                  style: { width: '200px', wordBreak: 'break-word' },
                  showOnDisabled: true,
                }}
                onClick={async () => {
                  await checkStartDate();
                  setShowConfirmPopup((ps) => ({ ...ps, submit: true }));
                }}
              />
            </div>
          )}

          {priceTableId > 0 && isDeletable === YN.YES && (
            <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
              <ConfirmDialog
                visible={showConfirmPopup['delete']}
                header="확인"
                message="점검료 테이블을 삭제하시겠습니까?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="네"
                acceptIcon="pi pi-check"
                acceptClassName="p-button-danger"
                accept={async () => {
                  const { data, error } = await baseCheckPriceService.delete(
                    priceTableId
                  );
                  console.log(data, error);

                  if (data) {
                    window.cerp.toast.success(
                      `점검료 테이블 삭제 완료`,
                      `점검료 테이블을 삭제했습니다.`
                    );
                    onHide();
                  }

                  if (error)
                    window.cerp.dialog.error(
                      '점검료 테이블 삭제 실패',
                      `[${error?.code}] ${error?.message}`
                    );
                }}
                rejectLabel="아니오"
                reject={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                }
                onHide={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                }
              />
              <Button
                type="button"
                label="삭제"
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: true }))
                }
              />
            </div>
          )}
        </div>
      }
    >
      <div className="bg-white sticky z-1 top-0 mb-3">
        <div className="grid">
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="field mb-0">
              <label>소속협회</label>
              <Dropdown
                className="w-full"
                value={selectedAssociationId}
                options={codes.associations}
                onChange={async ({ value }) => {
                  console.log('소속협회', value);
                  const [enterprises, enterpriseId] = await getEnterpriseCodes({
                    associationId: value,
                  });
                  console.log(enterprises, enterpriseId);
                  setCodes((ps) => ({ ...ps, enterprises }));
                  setSelectedAssociationId(value);
                  setSelectedEnterpriseId(enterpriseId);
                }}
                disabled={
                  !_.includes([UserPosition.Erp], myUserPosition) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                filter
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '협회가 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="field mb-0">
              <label>소속점검법인</label>
              <Dropdown
                className="w-full"
                value={selectedEnterpriseId}
                options={codes.enterprises}
                onChange={async ({ value }) => {
                  setSelectedEnterpriseId(value);
                }}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                filter
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '점검법인이 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-6 lg:col-3" />
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="w-full h-full flex flex-row align-items-end justify-content-start">
              {priceTableId === 0 && !isSubmitDone ? (
                <>
                  <div
                    {...getRootProps({
                      className: '',
                      style: { wordBreak: 'keep-all' },
                    })}
                  >
                    <input {...getInputProps()} />
                  </div>
                  <SplitButton
                    className="w-full"
                    buttonClassName="w-full p-button-outlined"
                    menuButtonClassName="p-button-outlined"
                    label="엑셀불러오기"
                    icon="pi pi-folder-open"
                    onClick={open}
                    model={[
                      {
                        label: '양식다운로드',
                        icon: 'pi pi-file-excel',
                        disabled: isSubmitDone,
                        command: async (e) => {
                          const workbook = new ExcelJS.Workbook();
                          const worksheet = workbook.addWorksheet('점검료양식');

                          worksheet.mergeCells('A1', 'C1');
                          worksheet.getCell('A1').value =
                            '※ 임의로 양식을 수정하지 마세요!';
                          worksheet.getCell('A1').font = {
                            size: 14,
                            bold: true,
                            color: { argb: 'ffb32d23' },
                          };
                          worksheet.getCell('A1').alignment = {
                            horizontal: 'center',
                            vertical: 'middle',
                          };
                          // worksheet.getCell('A1').fill = {
                          //   type: 'pattern',
                          //   pattern: 'solid',
                          //   fgColor: {argb: 'FFFFFF00'},
                          //   bgColor: {argb: 'FF0000FF'},
                          // };

                          worksheet.getRow(3).values = [
                            '점검료 구분',
                            '점검료 이름',
                            '점검료',
                          ];
                          worksheet.getCell('A3').alignment = {
                            horizontal: 'center',
                            vertical: 'middle',
                          };
                          worksheet.getCell('B3').alignment = {
                            horizontal: 'center',
                            vertical: 'middle',
                          };
                          worksheet.getCell('C3').alignment = {
                            horizontal: 'center',
                            vertical: 'middle',
                          };

                          worksheet.columns = [
                            {
                              key: 'priceType',
                              width: 15,
                              style: {
                                alignment: {
                                  horizontal: 'center',
                                  vertical: 'middle',
                                },
                              },
                            },
                            {
                              key: 'title',
                              width: 50,
                              style: {
                                alignment: {
                                  horizontal: 'center',
                                  vertical: 'middle',
                                },
                              },
                            },
                            {
                              key: 'price',
                              width: 20,
                              style: {
                                alignment: {
                                  horizontal: 'center',
                                  vertical: 'middle',
                                },
                              },
                            },
                          ];

                          for (let i = 4; i < 100; i++) {
                            worksheet.getCell('A' + i).dataValidation = {
                              type: 'list',
                              allowBlank: false,
                              formulae: [
                                `"${_.join(
                                  _.map(VEHICLE_TYPE_LEVEL1, 'label'),
                                  ','
                                )}"`,
                              ],
                              showErrorMessage: true,
                              errorStyle: 'error',
                            };
                          }

                          const mimeType = {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          };
                          const buffer = await workbook.xlsx.writeBuffer();
                          const blob = new Blob([buffer], mimeType);
                          saveAs(blob, '점검료양식.xlsx');
                        },
                      },
                    ]}
                  />
                </>
              ) : (
                <Button
                  icon="pi pi-download"
                  label="점검료 테이블 내려받기"
                  className="w-full p-button-outlined"
                  onClick={async (e) => {
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('점검료테이블');

                    worksheet.mergeCells('A1', 'C1');
                    worksheet.getCell('A1').value =
                      '※ 임의로 양식을 수정하지 마세요!';
                    worksheet.getCell('A1').font = {
                      size: 14,
                      bold: true,
                      color: { argb: 'ffb32d23' },
                    };
                    worksheet.getCell('A1').alignment = {
                      horizontal: 'center',
                      vertical: 'middle',
                    };

                    worksheet.getRow(3).values = [
                      '점검료 구분',
                      '점검료 이름',
                      '점검료',
                    ];
                    worksheet.getCell('A3').alignment = {
                      horizontal: 'center',
                      vertical: 'middle',
                    };
                    worksheet.getCell('B3').alignment = {
                      horizontal: 'center',
                      vertical: 'middle',
                    };
                    worksheet.getCell('C3').alignment = {
                      horizontal: 'center',
                      vertical: 'middle',
                    };

                    worksheet.columns = [
                      {
                        key: 'priceType',
                        width: 15,
                        style: {
                          alignment: {
                            horizontal: 'center',
                            vertical: 'middle',
                          },
                        },
                      },
                      {
                        key: 'title',
                        width: 50,
                        style: {
                          alignment: {
                            horizontal: 'center',
                            vertical: 'middle',
                          },
                        },
                      },
                      {
                        key: 'price',
                        width: 20,
                        style: {
                          alignment: {
                            horizontal: 'center',
                            vertical: 'middle',
                          },
                        },
                      },
                    ];

                    //* checkPriceTable.tableData 가져와서
                    _.forEach(checkPriceTable.tableData, (data) => {
                      let rowData = {
                        priceType: '',
                        title: '',
                        price: '',
                      };

                      for (let field in data) {
                        if (field !== 'id' && field !== 'priceType') {
                          rowData[field] = data[field];
                        }
                        if (field === 'priceType') {
                          rowData[field] = _.get(
                            _.find(VEHICLE_TYPE_LEVEL1, { value: data[field] }),
                            'label'
                          );
                        }
                      }
                      worksheet.addRow(rowData);
                    });

                    for (let i = 4; i < 100; i++) {
                      worksheet.getCell('A' + i).dataValidation = {
                        type: 'list',
                        allowBlank: false,
                        formulae: [
                          `"${_.join(
                            _.map(VEHICLE_TYPE_LEVEL1, 'label'),
                            ','
                          )}"`,
                        ],
                        showErrorMessage: true,
                        errorStyle: 'error',
                      };
                    }

                    const mimeType = {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    };
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], mimeType);
                    saveAs(blob, '점검료테이블.xlsx');
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <DataTable
          size="small"
          value={_.get(checkPriceTable, 'tableData')}
          header={
            <div className="flex flex-row align-items-center justify-content-between">
              <div className="font-bold text-lg">점검료 리스트</div>
              {priceTableId === 0 && (
                <div className="flex flex-row align-items-center justify-content-between gap-1">
                  <Button
                    type="button"
                    label="점검료 추가"
                    icon="pi pi-plus"
                    onClick={() => setCurrentItem({})}
                  />
                </div>
              )}
            </div>
          }
          responsiveLayout="scroll"
          showGridlines
          resizableColumns
          className="border-1 border-gray-50"
          emptyMessage="등록된 점검료 없음"
        >
          {/*<Column field="id" header="ID" />*/}
          <Column
            field="priceType"
            header="점검료 구분"
            body={({ priceType }) => columnCheckPriceType(priceType)}
          />
          <Column field="title" header="점검료 이름" />
          <Column
            className="text-right"
            field="price"
            header="금액"
            body={({ price }) =>
              new Intl.NumberFormat('ko-KR', { style: 'decimal' }).format(price)
            }
          />

          {/* {checkPriceTable === null && ( */}
          {priceTableId === 0 && (
            <Column
              className="w-1rem"
              body={(rowData, column) => (
                <div className="flex flex-row align-items-center justify-content-between gap-1">
                  <Button
                    type="button"
                    className="p-button-rounded"
                    icon="pi pi-pencil"
                    onClick={() => setCurrentItem(rowData)}
                  />
                  <Button
                    type="button"
                    className="p-button-rounded p-button-danger"
                    icon="pi pi-trash"
                    onClick={() => {
                      const clone = [..._.get(checkPriceTable, 'tableData')];
                      clone.splice(column.rowIndex, 1);
                      setCheckPriceTable((ps) => ({ ...ps, tableData: clone }));
                    }}
                  />
                </div>
              )}
            />
          )}
        </DataTable>

        {currentItem !== null && (
          <CheckPriceItemDialog
            userInfo={userInfo}
            priceItem={currentItem}
            onHide={(isNew, data) => {
              if (data) {
                if (isNew) {
                  setCheckPriceTable((ps) => {
                    const tableData = _.get(ps, 'tableData') || [];
                    return { ...ps, tableData: [...tableData, data] };
                  });
                } else {
                  const index = _.findIndex(
                    _.get(checkPriceTable, 'tableData') || [],
                    { id: data.id }
                  );
                  setCheckPriceTable((ps) => {
                    const tableData = _.get(ps, 'tableData');
                    tableData[index] = data;
                    return { ...ps, tableData };
                  });
                }
              }
              setCurrentItem(null);
            }}
          />
        )}
      </BlockUI>
    </Dialog>
  );
};

export default BaseCheckPriceDialog;
