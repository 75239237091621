import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';
import axios from 'axios';

const BASE_URI = '/apis/claim';

export const ClaimService = (function () {
  let instance;

  const init = () => {
    const baseService = BaseService.getInstance(BASE_URI, 'claim');

    return {
      ...baseService,
      getList: async (data) => {
        const result = await AxiosUtil.Async.get(BASE_URI, data);
        // console.log(result);
        return result.data;
      },
    };
  };

  return {
    getInstance: () => {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const Claim = {
  getList: async (data) => {
    const result = await axios.get(`${BASE_URI}`, {
      params: data,
    });
    return result.data.data;
  },

  getCheckWarranty: async (carNbr, VIN) => {
    const result = await axios.get(`${BASE_URI}/call`, {
      params: {
        carNbr: carNbr,
        VIN: VIN ? VIN : null,
      },
    });
    return result.data.data;
  },

  getDetail: async (dataId) => {
    const result = await AxiosUtil.Async.get(`${BASE_URI}/${dataId}`);
    return result.data;
  },

  registerClaim: async (data, files) => {
    const result = await AxiosUtil.Async.multipart(`${BASE_URI}`, data, files);
    return result;
  },

  getBase64Url: async function (imgUrl) {
    const encodedImgUrl = encodeURIComponent(imgUrl);
    const queryString = `imageUrl=${encodedImgUrl}`;
    return await AxiosUtil.Async.get(
      '/office/rest/extservice/base64encoding',
      queryString
    );
  },

  updateClaim: async (data, files) => {
    const result = await AxiosUtil.Async.updateMultipart(
      `${BASE_URI}`,
      data,
      files
    );
    return result.data;
  },

  deleteClaim: async (dataId) => {
    const result = await AxiosUtil.Async.delete(`${BASE_URI}/${dataId}`);
    return result.data;
  },

  getConsultation: async (data) => {
    const result = await axios.get(`${BASE_URI}/consultation`, {
      params: data,
    });
    return result.data.data;
  },

  posetConsultation: async (data) => {
    const result = await axios.post(`${BASE_URI}/consultation`, null, {
      params: data,
    });
    return result.data;
  },

  updateConsultation: async (data) => {
    const result = await axios.put(`${BASE_URI}/consultation`, null, {
      params: data,
    });
    return result.data;
  },

  getDiagnosisType: async () => {
    const result = await axios.get(`${BASE_URI}/code/maintenance`);
    return result.data.data;
  },

  getDocsType: async (type) => {
    const reulst = await axios.get(`${BASE_URI}/code/document/${type}`);
    return reulst.data;
  },

  getInsurances: async () => {
    const result = await axios.get(`/apis/codes/insurances`);
    return result.data.data;
  },

  postSMS: async (data, files) => {
    const result = await AxiosUtil.Async.multipart(
      `${BASE_URI}/msg`,
      data,
      files
    );
    return result.data;
  },

  /**
   * user_auth 없는 클레임 상세조회
   * @params carNbr, customerName, customerContact
   * @returns 클레임 상세
   */
  getClaimDetail: async (data) => {
    const result = await axios.get(`${BASE_URI}/app`, { params: data });
    return result.data.data;
  },

  getCarDetail: async (carNbr) => {
    const result = await axios.get(`${BASE_URI}/app/${carNbr}`);
    return result.data.data;
  },

  partnerUpload: async (data, files) => {
    const result = await AxiosUtil.Async.updateMultipart(
      '/apis/claim/partner',
      data,
      files
    );
    return result.data;
  },
};

export default Claim;
