import { Controller, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import Logo from '../assets/icons/logo_ikaat.png';
import { Password } from 'primereact/password';
import { ServiceProvider } from '../services';
import { useHistory } from 'react-router-dom';
import { userState, myInfoState } from '../recoil/atoms';

const defaultValues = {
  loginId: '',
  password: '',
  keepInfo: false,
};

const authService = ServiceProvider.auth;

const Login = () => {
  const history = useHistory();

  const [user, setUser] = useRecoilState(userState);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  useEffect(() => {
    const keptInfo = JSON.parse(localStorage.getItem('keptInfo'));

    if (keptInfo) {
      setValue('loginId', keptInfo.loginId);
      setValue('password', keptInfo.password);
      setValue('keepInfo', true);
    }
  }, [setValue]);

  useEffect(() => {
    const accessTokenNotExists = localStorage.getItem('accessToken') === null;
    const isLoggedInNotExists = localStorage.getItem('isLoggedIn') === null;

    if (
      (accessTokenNotExists && !isLoggedInNotExists) ||
      (!accessTokenNotExists && isLoggedInNotExists)
    ) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('isLoggedIn');

      window.cerp.dialog.error('인증정보 오류', '다시 로그인 해주세요.');
    }
  }, []);

  async function doLogin({ loginId, password, keepInfo }) {
    try {
      const {
        data: { accessToken },
      } = await authService.login(loginId, password);

      if (keepInfo) {
        localStorage.setItem('keptInfo', JSON.stringify({ loginId, password }));
      } else {
        localStorage.removeItem('keptInfo');
      }

      // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('isLoggedIn', 'Y');

      setUser({ isLoggedIn: true, accessToken: accessToken });

      history.replace('/');
    } catch (error) {
      window.cerp.dialog.error(
        '로그인 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  }

  return (
    <div className="w-full h-screen flex align-items-center justify-content-center">
      <div className="surface-card py-8 px-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          <img
            // src="images/blocks/logos/hyper.svg"
            src={Logo}
            alt="logo"
            height="100"
            className="mb-3 "
          />
          <div className="text-900 text-3xl font-medium mb-3 font-bold">
            한독자동차 ERP
          </div>
          {/* <span className="text-600 font-medium line-height-3">
            Don't have an account?
          </span>
          <a
            href="."
            role="button"
            onClick={(e) => e.preventDefault()}
            className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          >
            Create today!
          </a> */}
        </div>

        <div>
          <form>
            <label
              htmlFor="loginId"
              className="block text-900 font-medium mb-2"
            >
              아이디
            </label>
            <Controller
              control={control}
              name="loginId"
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  type="text"
                  className="w-full mb-3"
                  {...field}
                />
              )}
            />

            <label
              htmlFor="password1"
              className="block text-900 font-medium mb-2"
            >
              비밀번호
            </label>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => (
                <Password
                  id={field.name}
                  className="w-full mb-3"
                  inputClassName="w-full"
                  {...field}
                  feedback={false}
                  onKeyUp={({ key }) =>
                    key === 'Enter' && handleSubmit(doLogin)()
                  }
                />
              )}
            />

            <div className="flex align-items-center justify-content-between mb-6">
              <div className="flex align-items-center">
                <Controller
                  control={control}
                  name="keepInfo"
                  render={({ field, fieldState }) => (
                    <Checkbox
                      inputId={field.name}
                      binary
                      className="mr-2"
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
                <label htmlFor="keepInfo">아이디, 비밀번호 저장</label>
              </div>
              {/* <a
                href="."
                role="button"
                onClick={(e) => e.preventDefault()}
                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
              >
                Forgot password?
              </a> */}
            </div>

            <Button
              type="button"
              label="로그인"
              icon="pi pi-sign-in"
              className="w-full"
              onClick={() => handleSubmit(doLogin)()}
            />
          </form>
        </div>
        {/* <Divider />
        <div className="text-center">
          <a
            href="."
            role="button"
            onClick={(e) => e.preventDefault()}
            className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
          >
            비회원 성능점검 조회
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
