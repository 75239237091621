import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import _ from 'lodash';

import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import React, { useState, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { compressImages } from '../../utils/compressUtil';
import FileState from '../../enums/FileState';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Claim from '../../services/ClaimService';

const PartnerDocument = ({
  fileItems,
  setFileItems,
  deleteImgs,
  setDeleteImgs,
  copyFile,
  carDetailData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  let { dealer } = useParams();
  const dealerUser = dealer?.split('=')[1];

  const P_CHECK = useRef(null);
  const P_MAINTENANCE = useRef(null);
  const P_PART = useRef(null);
  const P_LIST = useRef(null);
  const P_ASSET = useRef(null);
  const P_REGISTRATION = useRef(null);
  const P_BANKBOOK = useRef(null);
  const P_OPINION = useRef(null);

  const deleteFile = (e) => {
    const trashFile = fileItems.filter(
      (el) => el.documentCode === e.target.name
    );

    setDeleteImgs(deleteImgs.concat(trashFile));

    const deletedList = fileItems.filter(
      (el) => el.documentCode !== e.target.name
    );
    setFileItems(deletedList);
  };

  const checkImg = fileItems?.filter((el) => el.documentCode === 'P_CHECK');
  const maintenanceImg = fileItems?.filter(
    (el) => el.documentCode === 'P_MAINTENANCE'
  );
  const partImg = fileItems?.filter((el) => el.documentCode === 'P_PART');
  const listImg = fileItems?.filter((el) => el.documentCode === 'P_LIST');
  const assetImg = fileItems?.filter((el) => el.documentCode === 'P_ASSET');
  const registerImg = fileItems?.filter(
    (el) => el.documentCode === 'P_REGISTRATION'
  );
  const bankImg = fileItems?.filter((el) => el.documentCode === 'P_BANKBOOK');
  const optionImg = fileItems?.filter((el) => el.documentCode === 'P_OPINION');

  async function getEncodedImgUrl(imageUrl) {
    const url = _.startsWith(imageUrl, 'http')
      ? imageUrl
      : process.env.REACT_APP_S3_BASE_URL + imageUrl;
    const { data: encodedUrl } = await Claim.getBase64Url(url);
    return encodedUrl;
  }

  const saveFilesAsZip = (title) => {
    const folderName =
      carDetailData?.performanceCheckData.VHRNO +
      `${title === '진단' ? '_진단서류' : '_수리서류'}`;
    const files =
      title === '진단'
        ? [...checkImg]
        : [
            ...maintenanceImg,
            ...partImg,
            ...listImg,
            ...assetImg,
            ...registerImg,
            ...bankImg,
            ...optionImg,
          ];
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const remoteZips = files.map(async (file) => {
      const fetchedFile = await getEncodedImgUrl(file.filePath);
      return fetchedFile;
    });
    remoteZips.forEach((imgBlob, index) => {
      imagesFolder.file(`img_${index + 1}.jpg`, imgBlob, {
        blob: true,
        base64: true,
      });
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const handleClick = (e) => {
    switch (e.target.id) {
      case '진단사진':
        P_CHECK.current.click();
        break;
      case '정비사진':
        P_MAINTENANCE.current.click();
        break;
      case '부품 신품 증빙사진':
        P_PART.current.click();
        break;
      case '정비명세서(직인필수)':
        P_LIST.current.click();
        break;
      case '자산발급영수증':
        P_ASSET.current.click();
        break;
      case '사업자등록증사본':
        P_REGISTRATION.current.click();
        break;
      case '사업자통장사본':
        P_BANKBOOK.current.click();
        break;
      case '소견서':
        P_OPINION.current.click();
        break;
      default:
        break;
    }
  };

  const uploadFiles = async (acceptedFiles, title) => {
    setLoading(true);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    const files = Object.values(acceptedFiles);
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    const compressedImages = await compressImages(files, options);
    compressedImages.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    const code = DOC_TYPE.find((el) => el.label === title)?.value;
    const partCodeAttachedFiles = _.reduce(
      compressedImages,
      (res, v) => {
        res.push({
          documentCode: code,
          file: v,
          state: FileState.Inserted,
        });
        return res;
      },
      []
    );

    setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    setLoading(false);
    window.cerp.toast.info('등록이 완료 되었습니다.');
  };

  return (
    <>
      <Panel
        header="4. 서류접수 (진단점)"
        className="pt-3 sm:text-base text-2xl"
      >
        <BlockUI
          template={
            loading &&
            dealerUser !== undefined && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="grid">
            <div className="col-12">
              <Card
                className="sm:pr-0 pr-4"
                // header={
                //   <div className="flex justify-content-between p-3">
                //     <div className="buttons_wrapper">
                //       <Button.Default
                //         icon="pi pi-send"
                //         label="진단 사진 SMS"
                //         className="p-button-outlined mr-2"
                //       />
                //       <Button.Default
                //         icon="pi pi-send"
                //         label="수리 사진 SMS"
                //         className="p-button-outlined"
                //       />
                //     </div>
                //   </div>
                // }
              >
                <div className="field grid">
                  <div className=" col-12">
                    <p className="text-primary sm:text-lg text-2xl py-2 font-semibold">
                      진단 사진
                    </p>
                    <div className="flex">
                      <div className="col p-0">
                        <div className="flex justify-content-between align-items-center h-3rem sm:text-base text-2xl">
                          <div className="col font-bold">진단사진</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {checkImg?.length > 0
                              ? checkImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {checkImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(checkImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.8,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_CHECK"
                                />
                              </>
                            )}
                          </div>
                          {!checkImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="진단사진"
                                />
                              </div>
                              <input
                                ref={P_CHECK}
                                id="진단사진"
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(e.target.files, '진단사진')
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {!dealerUser && (
                        <div className="col mt-2 p-0">
                          <div className="flex justify-content-end">
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="진단서류 다운로드"
                              onClick={() => saveFilesAsZip('진단')}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider type="solid" />
                  <div className="col-12">
                    <p className="text-primary sm:text-lg text-2xl py-2 font-semibold">
                      수리 관련 서류
                    </p>
                    <div className="grid ">
                      <div className="sm:col-6 col-12">
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">정비사진</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {maintenanceImg?.length > 0
                              ? maintenanceImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {maintenanceImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(maintenanceImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_MAINTENANCE"
                                />
                              </>
                            )}
                          </div>
                          {!maintenanceImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="정비사진"
                                />
                              </div>
                              <input
                                ref={P_MAINTENANCE}
                                id="정비사진"
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(e.target.files, '정비사진')
                                }
                              />
                            </>
                          )}
                        </div>

                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">부품신품 증빙사진</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {partImg?.length > 0
                              ? partImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {partImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(partImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_PART"
                                />
                              </>
                            )}
                          </div>
                          {!partImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="부품 신품 증빙사진"
                                />
                              </div>
                              <input
                                ref={P_PART}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(
                                    e.target.files,
                                    '부품 신품 증빙사진'
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">정비명세서</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {listImg?.length > 0
                              ? listImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {listImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(listImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_LIST"
                                />
                              </>
                            )}
                          </div>
                          {!listImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="정비명세서(직인필수)"
                                />
                              </div>
                              <input
                                ref={P_LIST}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(
                                    e.target.files,
                                    '정비명세서(직인필수)'
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">자산 발급영수증</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {assetImg?.length > 0
                              ? assetImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {assetImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(assetImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_ASSET"
                                />
                              </>
                            )}
                          </div>
                          {!assetImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="자산발급영수증"
                                />
                              </div>
                              <input
                                ref={P_ASSET}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(e.target.files, '자산발급영수증')
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="sm:col-6 col-12">
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">사업자등록증</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {registerImg?.length > 0
                              ? registerImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {registerImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(registerImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_REGISTRATION"
                                />
                              </>
                            )}
                          </div>
                          {!registerImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="사업자등록증사본"
                                />
                              </div>
                              <input
                                ref={P_REGISTRATION}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(
                                    e.target.files,
                                    '사업자등록증사본'
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">사업자통장사본</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {bankImg?.length > 0
                              ? bankImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {bankImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(bankImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_BANKBOOK"
                                />
                              </>
                            )}
                          </div>
                          {!bankImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="사업자통장사본"
                                />
                              </div>
                              <input
                                ref={P_BANKBOOK}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(e.target.files, '사업자통장사본')
                                }
                              />
                            </>
                          )}
                        </div>
                        <div className="flex justify-content-between align-items-center sm:h-3rem h-5rem sm:text-base text-2xl">
                          <div className="col font-bold">소견서</div>
                          <div className="sm:mr-0 mr-3 sm:text-base text-lg">
                            {optionImg?.length > 0
                              ? optionImg.length + ' 장'
                              : '접수 대기중'}
                          </div>
                          <div>
                            {optionImg?.length > 0 && (
                              <>
                                <Button.Default
                                  label="보기"
                                  icon="pi pi-window-maximize"
                                  className="p-button-text"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      'images',
                                      JSON.stringify(optionImg)
                                    );
                                    CommonUtil.Window.popup(
                                      '/compensation/accident/claim/viewer',
                                      '',
                                      window.innerWidth * 0.9,
                                      window.innerHeight * 0.9
                                    );
                                  }}
                                />
                                <Button.Default
                                  icon="pi pi-trash"
                                  label=""
                                  className="p-button-text"
                                  onClick={(e) => {
                                    deleteFile(e);
                                  }}
                                  name="P_OPINION"
                                />
                              </>
                            )}
                          </div>
                          {!optionImg?.length && (
                            <>
                              <div
                                onClick={
                                  !dealer
                                    ? (e) => {
                                        setCurTitle(e.target.id);
                                        setShowModal(true);
                                      }
                                    : (e) => handleClick(e)
                                }
                                className="flex justify-content-center cursor-pointer"
                              >
                                <i
                                  style={{
                                    color: 'var(--primary-color)',
                                    width: '28px',
                                  }}
                                  className="pi pi-upload sm:text-base text-2xl flex justify-content-center"
                                  id="소견서"
                                />
                              </div>
                              <input
                                ref={P_OPINION}
                                className="hidden"
                                type="file"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) =>
                                  uploadFiles(e.target.files, '소견서')
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="flex justify-content-end align-items-center h-3rem mt-2 ">
                          {!dealerUser && (
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="수리서류 다운로드"
                              onClick={() => saveFilesAsZip('수리')}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </BlockUI>
      </Panel>

      {showModal && curTitle && (
        <ImagesPopup
          title={curTitle}
          onHide={() => setShowModal(!showModal)}
          setFileItems={setFileItems}
          copyFile={copyFile}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default PartnerDocument;

const ImagesPopup = ({
  onHide,
  images,
  title,
  setFileItems,
  loading,
  setLoading,
  copyFile,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      // console.log(acceptedFiles);
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const code = DOC_TYPE.find((el) => el.label === title).value;
      // const findCode = copyFile.find((el) => el.documentCode === code);
      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: code,
            file: v,
            // state: findCode ? FileState.Updated : FileState.Inserted,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
      setLoading(false);
      onHide();
      window.cerp.toast.info('등록이 완료 되었습니다.');
    },
  });
  return (
    <>
      <Modal.Default
        header={`${title} 등록`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  사진을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};

const DOC_TYPE = [
  {
    value: 'P_CHECK',
    label: '진단사진',
  },
  {
    value: 'P_MAINTENANCE',
    label: '정비사진',
  },
  {
    value: 'P_PART',
    label: '부품 신품 증빙사진',
  },
  {
    value: 'P_LIST',
    label: '정비명세서(직인필수)',
  },
  {
    value: 'P_ASSET',
    label: '자산발급영수증',
  },
  {
    value: 'P_REGISTRATION',
    label: '사업자등록증사본',
  },
  {
    value: 'P_BANKBOOK',
    label: '사업자통장사본',
  },
  {
    value: 'P_OPINION',
    label: '소견서',
  },
];
