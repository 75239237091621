import { atom } from 'recoil';

export const touchUIState = atom({
  key: 'touchUI',
  default: false,
});

export const userState = atom({
  key: 'userState',
  default: {
    isLoggedIn: false,
    accessToken: null,
  },
});

export const myInfoState = atom({
  key: 'myInfo',
  default: {
    userId: 0,
    userName: '',
    loginId: '',
    contact: '',
    email: '',
    roleCode: '',
    roleName: '',
    userPosition: '',
    associationInfo: {
      associationId: null,
      associationCode: null,
      associationName: null,
    },
    enterpriseInfo: {
      enterpriseId: null,
      enterpriseCode: null,
      enterpriseName: null,
    },
    shopInfo: {
      shopId: null,
      shopCode: null,
      shopName: null,
    },
  },
});

export const noticeState = atom({
  key: 'notice',
  default: [],
});
